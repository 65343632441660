import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Star } from 'react-feather';

import { ReactComponent as ReminderIcon } from '../../assets/icon/ic-reminder-filled-gray.svg';
import { ReactComponent as NotificationAvatar } from '../../assets/icon/ic_avatar.svg';
import { ReactComponent as AddTimeIcon } from '../../assets/icon/ic-add-time-enabled.svg';
import { ReactComponent as ResetUsedTimeIcon } from '../../assets/icon/ic-reset-time.svg';
import { ReactComponent as ResetTargetTimeIcon } from '../../assets/icon/reset-target-time.svg';
import { ReactComponent as SetTargetTimeIcon } from '../../assets/icon/set-target-time.svg';
import { ReactComponent as NotesIcon } from '../../assets/icon/ic-notes.svg';
import { ReactComponent as OrderIcon } from '../../assets/icon/ic_order.svg';
import { ReactComponent as AuthenticationRequestIcon } from '../../assets/icon/ic_auth_request.svg';

import {
  NOTIFICATION_ACTION,
  NOTIFICATION_ACTION_ORDER,
  NOTIFICATION_OBJECT_TYPE,
  NOTIFICATION_STATUS,
  ORGANIZATION_TYPE,
} from '../../constants';
import { AUTHENTICATION_REQUEST_STATUSES } from '../CounterfeitRiskComponents/LegalTeam/constants';
import { findStatusDetails } from '../../utils/orders';

const NotificationItem = ({
  title,
  name,
  date,
  usersOrganizationType,
  handleClick,
  action,
  objectType,
  status,
  toggleStatus,
  allNotificationsSetting,
}) => {
  const { t } = useTranslation();

  const getNotification = (action, objectType, status) => {
    if (objectType === NOTIFICATION_OBJECT_TYPE.THREAD) {
      switch (action) {
        case NOTIFICATION_ACTION.CREATE:
          return {
            title: 'sharedComponents.addedANote',
            icon: <NotesIcon width={40} height={40} />,
          };
        case NOTIFICATION_ACTION.UPDATE:
          return {
            title: 'sharedComponents.editedANote',
            icon: <NotesIcon width={40} height={40} />,
          };
        case NOTIFICATION_ACTION.COMPLETE:
          return {
            title: 'sharedComponents.completedANote',
            icon: <NotesIcon width={40} height={40} />,
          };
        case NOTIFICATION_ACTION.REMIND:
          return {
            title: 'sharedComponents.noteReminder',
            icon: (
              <ReminderIcon
                className={`icon-${
                  status === NOTIFICATION_STATUS.UNREAD
                    ? 'secondary'
                    : 'tertiary'
                }`}
                width={36}
                height={36}
              />
            ),
          };
        default:
          return {
            title: 'sharedComponents.unknownEvent',
          };
      }
    }
    if (objectType === NOTIFICATION_OBJECT_TYPE.USAGE_TIME) {
      switch (action) {
        case NOTIFICATION_ACTION.ADD:
          return {
            title: 'sharedComponents.addedUsedTime',
            icon: (
              <AddTimeIcon className='icon-tertiary' height={36} width={36} />
            ),
          };
        case NOTIFICATION_ACTION.RESET:
          return {
            title: 'sharedComponents.resetUsedTime',
            icon: (
              <ResetUsedTimeIcon
                className='icon-tertiary'
                height={36}
                width={36}
              />
            ),
          };
        default:
          return {
            title: 'sharedComponents.unknownEvent',
          };
      }
    }
    if (objectType === NOTIFICATION_OBJECT_TYPE.TARGET_TIME) {
      switch (action) {
        case NOTIFICATION_ACTION.ADD:
          return {
            title: 'sharedComponents.setTargetTime',
            icon: (
              <SetTargetTimeIcon
                className='icon-tertiary'
                height={36}
                width={36}
              />
            ),
          };
        case NOTIFICATION_ACTION.RESET:
          return {
            title: 'sharedComponents.resetTargetTime',
            icon: (
              <ResetTargetTimeIcon
                className='icon-tertiary'
                height={36}
                width={36}
              />
            ),
          };
        default:
          return {
            title: 'sharedComponents.unknownEvent',
          };
      }
    }
    if (objectType === NOTIFICATION_OBJECT_TYPE.CONDITION_RATING) {
      switch (action) {
        case NOTIFICATION_ACTION.UPDATE:
          return {
            title: 'sharedComponents.changedConditionRating',
            icon: <Star className='notification-star' height={36} width={36} />,
          };
        default:
          return {
            title: 'sharedComponents.unknownEvent',
          };
      }
    }
    if (objectType === NOTIFICATION_OBJECT_TYPE.RECONDITION_ORDER) {
      switch (action) {
        case NOTIFICATION_ACTION_ORDER.PICKUP_REQUESTED:
          return {
            title: 'sharedComponents.orderCreated',
            titleSuffix: `: ${t('sharedComponents.pickupRequested')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.SHIPPING_IN_PROGRESS:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.shippingInProgress')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.DELIVERED_TO_RC:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.deliveredToReconCenter')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.INSPECTION_IN_PROGRESS:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.inspectionInProgress')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.RECONDITIONING_IN_PROGRESS:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${
              usersOrganizationType === ORGANIZATION_TYPE.SALES
                ? `${t(
                    'sharedComponents.reconditioningProcessInProgress'
                  )} - ${t('sharedComponents.inspectionReportReady')}`
                : t(
                    'sharedComponents.inspectionOfYourOrderIsDoneForMoreDetailsReachOutToYourSalesContact'
                  )
            }.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.VERIFICATION_IN_PROGRESS:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.verificationInProgress')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.READY_TO_BE_SHIPPED_TO_CUSTOMER:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.readyForShippingBack')}${
              usersOrganizationType === ORGANIZATION_TYPE.SALES
                ? ` - ${t('sharedComponents.verificationReportReady')}`
                : ''
            }.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.SHIPPED_TO_CUSTOMER:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.shippingBack')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.DELIVERED_TO_CUSTOMER:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.deliveredToCustomer')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        case NOTIFICATION_ACTION_ORDER.COMPLETED:
          return {
            title: 'sharedComponents.updateOnOrder',
            titleSuffix: `: ${t('sharedComponents.completed')}.`,
            icon: (
              <OrderIcon className='icon-tertiary' height={32} width={32} />
            ),
          };
        default:
          return {
            title: 'sharedComponents.unknownEvent',
          };
      }
    }

    if (objectType === NOTIFICATION_OBJECT_TYPE.AUTHENTICATION_REQUEST) {
      return {
        title: 'sharedComponents.authenticationRequestNumber',
        titleSuffix: ` ${t(
          findStatusDetails(AUTHENTICATION_REQUEST_STATUSES, action)[
            usersOrganizationType === ORGANIZATION_TYPE.CUSTOMER
              ? 'END_CUSTOMER_NOTIFICATION_TITLE_SUFFIX'
              : 'LEGAL_NOTIFICATION_TITLE_SUFFIX'
          ] || ''
        )}.`,
        icon: (
          <AuthenticationRequestIcon
            className='icon-tertiary'
            height={32}
            width={32}
          />
        ),
      };
    }
  };

  const notificationData = getNotification(action, objectType);

  return (
    <div
      className='d-flex w-100 notification-item border-bottom'
      onClick={handleClick}
    >
      <div className='d-flex px-3 align-items-start pt-2'>
        {notificationData?.icon || (
          <NotificationAvatar height={36} width={36} className='avatar-icon' />
        )}
      </div>
      <div className='d-flex flex-column flex-grow-1 justify-content-evenly w-75'>
        <div className='notification-title text-break'>
          <span>
            {action === NOTIFICATION_ACTION.REMIND ? (
              <Trans
                i18nKey={notificationData?.title}
                components={[<span className='font-weight-bold' />]}
                values={{ name: title }}
              />
            ) : (
              <Trans
                i18nKey={notificationData?.title}
                components={[<span className='font-weight-bold' />]}
                values={{ name, title }}
              />
            )}
            {notificationData.titleSuffix && t(notificationData.titleSuffix)}
          </span>
          {objectType === NOTIFICATION_OBJECT_TYPE.RECONDITION_ORDER && (
            <div className='notification-product text-overflow-wrap'>
              {title}
            </div>
          )}
        </div>
        {objectType !== NOTIFICATION_OBJECT_TYPE.RECONDITION_ORDER && (
          <div className='notification-product text-overflow-wrap'>{title}</div>
        )}
        <div className='notification-timestamp'>
          {action === NOTIFICATION_ACTION.REMIND ? (
            <Trans i18nKey='sharedComponents.dueDate' values={{ date }} />
          ) : (
            date
          )}
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        {allNotificationsSetting && (
          <div
            className='notification-dot-container d-flex justify-content-center align-items-center'
            onClick={toggleStatus}
          >
            <div
              className={cs('notification-dot', {
                'notification-dot-read border':
                  status === NOTIFICATION_STATUS.READ,
              })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  usersOrganizationType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  toggleStatus: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  allNotificationsSetting: PropTypes.bool.isRequired,
};

export default NotificationItem;
