import { PERMISSIONS, ROUTES, THEME_COLORS_NAMES } from '../../../constants';

export const STATUS_COLORS_NAMES = {
  SUMMER_SKY: 'status_summer_sky',
  TAHITI_GOLD: 'status_tahiti_gold',
  MOON_YELLOW: 'status_moon_yellow',
  MEDIUM_SEA_GREEN: 'status_medium_sea_green',
  LIGHT_SEA_GREEN: 'status_light_sea_green',
  JAVA: 'status_java',
};

export const PRODUCT_AUTHENTICATION_SIDEBAR = {
  INBOX: {
    PATH: ROUTES.LEGAL_INBOX,
    NAME: 'containers.inbox',
    PERMISSION: PERMISSIONS.VIEW_INBOX_IN_SIDEBAR,
  },
  INSPECTION: {
    PATH: ROUTES.LEGAL_INSPECTION,
    NAME: 'containers.inspection',
    PERMISSION: PERMISSIONS.VIEW_LEGAL_INSPECTION_IN_SIDEBAR,
  },
  IN_PROGRESS: {
    PATH: ROUTES.LEGAL_IN_PROGRESS,
    NAME: 'components.inProgress',
    PERMISSION: PERMISSIONS.VIEW_LEGAL_IN_PROGRESS_IN_SIDEBAR,
  },
  RESPONSE: {
    PATH: ROUTES.LEGAL_RESPONSE,
    NAME: 'containers.response',
    PERMISSION: PERMISSIONS.VIEW_RESPONSE_IN_SIDEBAR,
  },
  ARCHIVE: {
    PATH: ROUTES.LEGAL_ARCHIVE,
    NAME: 'containers.archive',
    PERMISSION: PERMISSIONS.VIEW_LEGAL_ARCHIVE_IN_SIDEBAR,
  },
};

const CLOSED_STATUS = {
  KEY: 'Closed',
  LABEL: 'components.closed',
  COLOR: THEME_COLORS_NAMES.BUTTON_DISABLED,
  SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.ARCHIVE,
  END_CUSTOMER_NOTIFICATION_TITLE_SUFFIX:
    'sharedComponents.conclusionHasBeenShared',
};

export const AUTHENTICATION_REQUEST_LEGAL_DIVISION_STATUSES = {
  DIVISION_TO_INSPECT: {
    KEY: 'DivisionToInspect',
    LABEL: 'components.toInspect',
    COLOR: STATUS_COLORS_NAMES.TAHITI_GOLD,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INBOX,
    LEGAL_NOTIFICATION_TITLE_SUFFIX: 'sharedComponents.newRequestToInspect',
  },
  DIVISION_INSPECTION_IN_PROGRESS: {
    KEY: 'DivisionInProgress',
    LABEL: 'components.inProgress',
    COLOR: STATUS_COLORS_NAMES.MOON_YELLOW,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION,
    END_CUSTOMER_NOTIFICATION_TITLE_SUFFIX:
      'sharedComponents.inspectionInProgress',
  },
  DIVISION_INSPECTED: {
    KEY: 'DivisionInspected',
    LABEL: 'components.inspected',
    COLOR: STATUS_COLORS_NAMES.MEDIUM_SEA_GREEN,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION,
  },
  DIVISION_REPLIED: {
    KEY: 'DivisionReplied',
    LABEL: 'components.replied',
    COLOR: STATUS_COLORS_NAMES.JAVA,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.RESPONSE,
  },
  CLOSED: CLOSED_STATUS,
};

export const AUTHENTICATION_REQUEST_LEGAL_STATUSES = {
  LEGAL_NEW: {
    KEY: 'LegalNew',
    LABEL: 'components.new',
    DIVISION_SIDE_STATUS:
      AUTHENTICATION_REQUEST_LEGAL_DIVISION_STATUSES.DIVISION_REPLIED,
    COLOR: STATUS_COLORS_NAMES.SUMMER_SKY,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.INBOX,
    LEGAL_NOTIFICATION_TITLE_SUFFIX: 'sharedComponents.newRequestReceived',
    END_CUSTOMER_NOTIFICATION_TITLE_SUFFIX:
      'sharedComponents.conclusionHasBeenShared',
  },
  LEGAL_IN_PROGRESS: {
    KEY: 'LegalInprogress',
    LABEL: 'components.inProgress',
    DIVISION_SIDE_STATUS:
      AUTHENTICATION_REQUEST_LEGAL_DIVISION_STATUSES.DIVISION_REPLIED,
    COLOR: STATUS_COLORS_NAMES.MOON_YELLOW,
    SIDEBAR_OPTION: PRODUCT_AUTHENTICATION_SIDEBAR.IN_PROGRESS,
  },
  CLOSED: CLOSED_STATUS,
};

export const AUTHENTICATION_REQUEST_STATUSES = {
  ...AUTHENTICATION_REQUEST_LEGAL_DIVISION_STATUSES,
  ...AUTHENTICATION_REQUEST_LEGAL_STATUSES,
};

export const STATUSES_WITH_CONCLUSION = [
  AUTHENTICATION_REQUEST_STATUSES.DIVISION_REPLIED.KEY,
  AUTHENTICATION_REQUEST_STATUSES.CLOSED.KEY,
];

export const INTERNAL_DISCUSSION_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'error.errorWhileLoadingComments',
  },
  NO_ITEMS_FOUND: {
    title: 'error.noCommentsYet',
  },
  NO_SEARCH_RESULTS: {
    title: 'error.noCommentsYet',
  },
};

export const LEGAL_DIVISION_LOCKED_STATUSES = [
  AUTHENTICATION_REQUEST_STATUSES.LEGAL_NEW.KEY,
  AUTHENTICATION_REQUEST_STATUSES.LEGAL_IN_PROGRESS.KEY,
];
