import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.user, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        details: payload.user,
      };
    case types.SET_MY_DETAILS_SUCCESS:
      return {
        ...state,
        language: payload.language,
        organization: payload.organizationData,
      };
    case types.GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: payload.settings,
      };
    case types.PATCH_USER_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...payload.user,
          picture: payload.user.picture || null,
        },
      };
    case types.UPDATE_USER_SETTINGS_PREFERENCES_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          updatePreference: payload.updatePreference,
        },
      };
    default:
      return state;
  }
}
