export const TAB_LABELS = {
  PRODUCT_DATA: 'product_data',
  ITEM_DETAILS: 'item_details',
  AS_PRODUCED: 'as_produced',
  CONDITION_RATINGS: 'condition_ratings',
  TIME_TRACKER: 'time_tracker',
  TECHNICAL: 'technical',
  NOTES: 'notes',
  PRODUCT_ITEMS: 'product_items',
  ASSEMBLY_ITEMS: 'assembly_items',
  ASSEMBLY_ITEM: 'assembly_item',
  ITEM_HISTORY: 'item_history',
  ASSEMBLIES: 'assemblies',
  RECONDITIONS: 'reconditions',
  RECONDITIONING: 'reconditioning',
  RECYCLING: 'recycling',
  CO2_FOOTPRINT: 'co2_footprint',
  PRODUCT_INFO: 'product_info',
  CUTTING_DATA: 'cutting_data',
  COMPONENTS: 'components',
};

export const TAB_LABELS_TRANS_KEYS = {
  PRODUCT_DATA: 'modal.productData',
  ITEM_DETAILS: 'modal.itemDetails',
  AS_PRODUCED: 'modal.asProduced',
  CONDITION_RATINGS: 'modal.conditionRatings',
  TIME_TRACKER: 'modal.timeTracker',
  TECHNICAL: 'modal.technical',
  NOTES: 'modal.notes',
  PRODUCT_ITEMS: 'modal.productItems',
  ASSEMBLY_ITEMS: 'components.assemblyItems',
  ASSEMBLY_ITEM: 'containers.assemblyItem',
  ITEM_HISTORY: 'modal.itemHistory',
  ASSEMBLIES: 'containers.assemblies',
  RECONDITIONS: 'containers.reconditions',
  RECONDITIONING: 'modal.reconditioning',
  RECYCLING: 'components.recycling',
  CO2_FOOTPRINT: 'modal.co2Footprint',
  PRODUCT_INFO: 'modal.productInfo',
  CUTTING_DATA: 'modal.cuttingData',
  COMPONENTS: 'containers.components',
  PRODUCTS: 'containers.products',
};

export const GENERAL_DROPDOWN_OPTIONS = {
  EDIT_PRODUCT_DETAILS: 'modal.editProductDetails',
  DELETE_PRODUCT: 'containers.deleteProduct',
};

export const BOX_TAG_DROPDOWN_OPTIONS = {
  SYNC_TAG: 'containers.syncBoxTag',
  SYNC_NEW_TAG: 'components.syncNewTag',
  UNSYNC_TAG: 'modal.unsyncTag',
};

export const BOX_OPTIONS_DROPDOWN_OPTIONS = {
  EDIT_CONFIGURATION: 'components.editBoxConfiguration',
  DELETE_BOX: 'components.deleteBox',
};

export const TAG_DROPDOWN_OPTIONS = {
  EDIT_TAG_DETAILS: 'containers.editTagDetails',
  TRANSFER_TAG: 'containers.transferTag',
  SYNC_NEW_TAG: 'containers.syncNewTag',
  DELETE_TAG: 'containers.deleteTag',
};

export const TIME_TRACKER_DROPDOWN_OPTIONS = {
  EDIT_UNIT: 'containers.editUnit',
  RESET_TO_DEFAULT_UNIT: 'containers.resetToDefaultUnit',
};

export const RECONDITIONING_DROPDOWN_OPTIONS = {
  ADD_RECONDITIONING_INFO: 'containers.addReconditioningInfo',
};
