import {
  DEVELOPMENT_ENVIRONMENT,
  FIREBASE_CREDENTIALS,
  STAGING_ENVIRONMENT,
  PRODUCTION_ENVIRONMENT,
  FIREBASE_APP_PROD,
} from './constants';

const getEnvVars = (origin = 'http://localhost:3000') => {
  const subdomain = origin.split('//')[1].split('.')[0];
  // production
  if (subdomain === 'bridge') {
    return {
      DB_API_URL: 'https://sms-bridge.sandvik.com/bridge',
      RBAC_API: 'https://sms-bridge.sandvik.com/rbac',
      BACKEND_AUD: 'https://sms-bridge.sandvik.com',
      AUTH0_CLIENT_ID: 'BdCVjN5bXr9rZAcnk2tuiPzxo73LDV1i',
      DOMAIN: 'auth.sandvik.com',
      NOTIFICATION_URL:
        'https://sms-bridge.sandvik.com/notifications/hub/v1/notifications',
      ENV: PRODUCTION_ENVIRONMENT,
      FIREBASE_CONFIG: {
        ...FIREBASE_CREDENTIALS,
        ...FIREBASE_APP_PROD,
      },
    };
  }
  // staging
  if (subdomain === 'web-app-stage') {
    return {
      DB_API_URL: 'https://sms-bridge-stage.sandvik.com',
      RBAC_API: 'https://sms-bridge-stage.sandvik.com/rbac',
      BACKEND_AUD: 'https://sms-bridge-stage.sandvik.com',
      AUTH0_CLIENT_ID: 'hkMmvrJiFT7I0LjEuCLKnjZYuDsRg2wF',
      DOMAIN: 'auth-stage.sandvik.com',
      NOTIFICATION_URL:
        'https://sms-bridge-stage.sandvik.com/notifications/hub/v1/notifications',
      ENV: STAGING_ENVIRONMENT,
      FIREBASE_CONFIG: {},
    };
  }
  if (subdomain === 'localhost:6001') {
    return {
      DB_API_URL: 'http://host.docker.internal:5000',
      RBAC_API: 'http://host.docker.internal:3001',
      BACKEND_AUD: 'https://backend-dev.idemtools.com',
      AUTH0_CLIENT_ID: 'uAA7WS9VLXVZSoZSP2gOpH1mNYIc7x7w',
      DOMAIN: 'dev-idem.eu.auth0.com',
      NOTIFICATION_URL:
        'https://bridgenotificationservicedev.azurewebsites.net/hub/v1/notifications',
      ENV: DEVELOPMENT_ENVIRONMENT,
      FIREBASE_CONFIG: {},
    };
  }
  return {
    DB_API_URL: 'https://app-bridge-dev-001.azurewebsites.net',
    RBAC_API: 'https://app-rbac-dev-001.azurewebsites.net',
    BACKEND_AUD: 'https://app-bridge-dev-001.azurewebsites.net',
    AUTH0_CLIENT_ID: 'uAA7WS9VLXVZSoZSP2gOpH1mNYIc7x7w',
    DOMAIN: 'dev-idem.eu.auth0.com',
    NOTIFICATION_URL:
      'https://app-notification-dev-001.azurewebsites.net/hub/v1/notifications',
    ENV: DEVELOPMENT_ENVIRONMENT,
    FIREBASE_CONFIG: {},
  };
};

const {
  DB_API_URL,
  RBAC_API,
  BACKEND_AUD,
  AUTH0_CLIENT_ID,
  NOTIFICATION_URL,
  ENV,
  DOMAIN,
  FIREBASE_CONFIG,
} = getEnvVars(window.origin);

export {
  DOMAIN,
  DB_API_URL,
  RBAC_API,
  BACKEND_AUD,
  AUTH0_CLIENT_ID,
  NOTIFICATION_URL,
  ENV,
  FIREBASE_CONFIG,
};
