import React from 'react';

import { LIMIT_REACHED_TYPE } from '../constants';
import { ReactComponent as MemberLimitReachedIcon } from '../assets/icon/img_member_limit_reached.svg';

export const getLimitReachedTitle = (limitReachedType) => {
  switch (limitReachedType) {
    case LIMIT_REACHED_TYPE.ORGANISATION_MEMBERS:
      return 'modal.memberLimitReached';
    default:
      return null;
  }
};

export const getLimitReachedSubtitle = (limitReachedType) => {
  switch (limitReachedType) {
    case LIMIT_REACHED_TYPE.ORGANISATION_MEMBERS:
      return 'modal.youCannotAddMoreMembersToThisOrganizationPleaseContactSecoSupportInOrderToChangeYourSubscriptionPlan';
    default:
      return null;
  }
};

export const getLimitReachedImage = (limitReachedType) => {
  switch (limitReachedType) {
    case LIMIT_REACHED_TYPE.ORGANISATION_MEMBERS:
      return (
        <MemberLimitReachedIcon
          className='member-limit-reached-icon'
          height={212}
          width={212}
        />
      );
    default:
      return null;
  }
};
