import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS, URLS } from '../constants';
import { openUrlInNewTab } from '../utils/shared';
import { formatDate } from '../utils/date';
import { getLocalStorageItem } from '../utils/localStorage';
import { APP_NAME_STORAGE_KEY, DEFAULT_APP_TITLE } from '../constants/theme';
import { Dot } from './Shared';

const Footer = () => {
  const { t } = useTranslation();
  const defaultAppName = getLocalStorageItem(
    APP_NAME_STORAGE_KEY,
    DEFAULT_APP_TITLE
  );
  const [appName, setAppName] = useState(defaultAppName);

  useEffect(() => {
    setAppName(getLocalStorageItem(APP_NAME_STORAGE_KEY, defaultAppName));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage]);

  const footerLinks = [
    {
      title: 'components.cookies',
      url: URLS.BRIDGE_COOKIES,
      showDot: false,
    },
  ];

  return (
    <div className='app-footer box-shadow'>
      <Row className='m-0'>
        <Col>
          {footerLinks.map(({ title, url, showDot }, index) => (
            <div key={index}>
              <p
                className='app-footer-link'
                onClick={() => openUrlInNewTab(url)}
              >
                {t(title)}
              </p>
              {showDot && <Dot className='dot' />}
            </div>
          ))}
        </Col>
        <Col className='justify-content-end'>
          <p className='mb-0'>{`© 2022-${formatDate(
            Date.now(),
            DATE_FORMATS.YEAR
          )} ${appName} ${process.env.REACT_APP_VERSION}`}</p>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
