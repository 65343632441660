import React from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';

import { Image, Table } from '../Shared';
import {
  displayArticleNumber,
  displayBoxProvider,
  displayBoxWeight,
  getProviderLogo,
} from '../../utils/box';
import { CUSTOM_BOX_PROVIDER } from '../../constants';

const PlaceInBoxBoxesTable = ({ boxes = [], handleRowClick = () => {} }) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      name: t('components.vendor'),
      style: { width: '17%' },
      isResizable: true,
      className: 'table-padding-left',
    },
    {
      name: t('components.boxName'),
      style: { width: '28%' },
      isResizable: true,
    },
    {
      name: t('components.articleNumber'),
      style: { width: '21%' },
      className: 'table-padding-right',
      isResizable: true,
    },
    {
      name: t('components.weight'),
      style: { width: '24%' },
    },
    {
      name: t('components.items'),
      value: 'productInstanceCount',
      style: { width: '10%' },
      isResizable: true,
    },
  ];

  return (
    <Table
      data={boxes.map((box, i) => {
        const { id, name, boxProvider, articleNumber, productInstanceCount } =
          box;

        return {
          rowColumns: [
            <div className='table-margin-left text-truncate font-size-sm pl-3'>
              {boxProvider === CUSTOM_BOX_PROVIDER ? (
                displayBoxProvider({ boxProvider })
              ) : (
                <Image
                  src={getProviderLogo(boxProvider)}
                  height={24}
                  width={62}
                />
              )}
            </div>,
            <div className='d-flex justify-content-between align-items-center font-weight-bold text-secondary w-100'>
              <span>{name.toUpperCase()}</span>
            </div>,
            <span className='flex-grow-1 text-truncate pr-1'>
              {displayArticleNumber({ articleNumber })}
            </span>,
            displayBoxWeight(box),
            <span className='flex-grow-1 text-truncate'>
              {productInstanceCount}
            </span>,
          ],
          rowClassName: cs('border-top py-3', {
            'border-bottom': i === boxes.length - 1,
            'cursor-pointer': handleRowClick,
          }),
          onClick: () => handleRowClick(id),
        };
      })}
      columns={tableColumns}
      striped={false}
      className='w-100 font-size-md'
      withHeader
    />
  );
};

PlaceInBoxBoxesTable.propTypes = {
  boxes: PropTypes.array,
  handleRowClick: PropTypes.func,
};

export default PlaceInBoxBoxesTable;
