import React from 'react';

import PropTypes from 'prop-types';

const Dot = ({ className }) => <span className={className}>•</span>;

Dot.propTypes = {
  className: PropTypes.string,
};

export default Dot;
