import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import cs from 'classnames';
import {
  Collapse,
  Navbar as NavbarComponent,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _debug from 'debug';
import i18next from 'i18next';

import { toggleSidebar } from '../redux/actions/sidebarActions';
import { useAuth0 } from '../react-auth0-spa';
import Notifications from './UserComponents/Notifications';
import {
  getUnreadNotificationsCount,
  setNotificationConnectionStatus,
} from '../redux/actions/notificationActions';
import { getUserSettings } from '../redux/actions/userActions';
import { getServerMetadata } from '../redux/actions/applicationActions';
import { handleAnalytics } from '../utils/analytics';
import { getPermissions } from '../utils/user';
import {
  createConnection,
  getNotificationNumber,
} from '../utils/notifications';
import flags from '../assets/img/flags';
import {
  FIREBASE_EVENTS,
  INTERNATIONALIZATION,
  MAX_NOTIFICATION_NUMBER,
  PERMISSIONS,
  ROUTES,
} from '../constants';
import { ReactComponent as Hamburger } from '../assets/icon/ic_side_bar_expander.svg';
import { ReactComponent as NotificationBell } from '../assets/icon/ic-bell-primary.svg';
import { ReactComponent as ShareEnvelop } from '../assets/icon/ic_top_menu_messages.svg';
import { ReactComponent as Feedback } from '../assets/icon/icfeedback.svg';
import { ReactComponent as WarningIcon } from '../assets/icon/risk.svg';
import { ReactComponent as Profile } from '../assets/icon/ic-profile-primary.svg';
import { ReactComponent as Scan } from '../assets/icon/ic-scan.svg';
import FeedbackModal from './Modals/Shared/FeedbackModal';
import ScanModal from './Modals/Shared/ScanModal';
import { BasicTooltip, BreadcrumbsHeader, Logger } from './Shared';
import { isRouteActive } from '../utils/routes';
import { patchUser } from '../client/user';

const debug = _debug('Bridge:Navbar');

const Navbar = ({
  breadcrumbs,
  organization,
  language,
  toggleSidebar,
  productInstance,
  assemblyInstance,
  getUnreadNotificationsCount,
  unreadCount,
  setNotificationConnectionStatus,
  notificationsConnected,
  getUserSettings,
  getServerMetadata,
  serverMetadataFetched,
}) => {
  const { t, i18n } = useTranslation();
  const { logout } = useAuth0();
  const history = useHistory();
  const { pathname } = useLocation();
  const permissions = getPermissions();

  const [languagesExpanded, setLanguagesExpanded] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notificationsOpen, setNotificationOpen] = useState(false);
  const [notificationSettingsOpen, setNotificationSettingsOpen] =
    useState(false);
  const [isFeedbackModalOpened, setIsFeedbackModalOpened] = useState(false);
  const [isScanModalOpen, setIsScanModalOpen] = useState(false);

  const handleOpenNotificationSettings = (isOpen) =>
    setNotificationSettingsOpen(isOpen);

  const matchProductsPath = useRouteMatch(`${ROUTES.DATABASE_PRODUCTS}`);
  const matchAssembliesPath = useRouteMatch(`${ROUTES.DATABASE_ASSEMBLIES}`);
  const matchBoxPath = useRouteMatch(ROUTES.RECONDITIONING_BOX);
  const matchOrderBoxPath = useRouteMatch(
    `${ROUTES.RECONDITIONING_ORDER}/:orderId/box/:boxId`
  );
  const matchOrderPath = useRouteMatch(ROUTES.RECONDITIONING_ORDER);
  const { id, tag, instanceId } = useParams();

  useEffect(() => {
    if (permissions.includes(PERMISSIONS.NOTIFICATION_ALL)) {
      (async () => {
        try {
          const connection = createConnection();
          if (!notificationsConnected) {
            await connection.start();
            setNotificationConnectionStatus(true);
            connection.on('Notifications', () => {
              getUnreadNotificationsCount();
            });
          }
          connection.on('onDisconnect', () => {
            setNotificationConnectionStatus(false);
          });
        } catch (e) {
          debug(`Couldn't connect to notification service. Reason: ${e}`);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeLanguage = async (lang) => {
    await patchUser({
      language: lang,
    });

    // support translations by brand
    const availableLanguageResources = Object.keys(i18next.options.resources);
    const languageByBrand = `${lang}${organization.brand?.id}`;

    await i18n.changeLanguage(
      availableLanguageResources.includes(languageByBrand)
        ? languageByBrand
        : lang
    );
  };

  useEffect(() => {
    if (organization.id) {
      handleChangeLanguage(language || 'en');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.includes(PERMISSIONS.NOTIFICATION_ALL)) {
          await Promise.all([getUnreadNotificationsCount(), getUserSettings()]);
        } else {
          await getUserSettings();
        }
      } catch (e) {
        debug(`Couldn't fetch notification settings. Reason: ${e}`);
      }
      if (!serverMetadataFetched) {
        try {
          await getServerMetadata();
        } catch (e) {
          debug(`Couldn't fetch server metadata. Reason: ${e}`);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLanguagesExpanded(false);
  }, [dropdownOpen]);

  const logoutWithRedirect = () => {
    handleAnalytics(FIREBASE_EVENTS.LOGOUT);
    localStorage.clear();
    logout({
      returnTo: window.location.origin,
    });
  };

  const renderLanguages = (item) => (
    <DropdownItem
      key={item.code}
      onClick={() => handleChangeLanguage(item.code)}
      className={cs({
        'bg-highlighted-bg': i18n.language.slice(0, 2) === item.code,
      })}
      disabled={!!item.disabled}
    >
      <img
        src={flags[item.code]}
        alt={item.language}
        width={20}
        className='align-middle mr-1'
      />
      <span className='align-middle'>{item.language}</span>
      {(item.code === 'de' || item.code === 'fr') && (
        <span className='font-italic ml-1 align-middle'>{`(${t(
          'containers.beta'
        )})`}</span>
      )}
    </DropdownItem>
  );

  const handleNotificationToggle = async () => {
    if (!notificationSettingsOpen) {
      setNotificationOpen((prevState) => !prevState);
    }
  };

  const openProductAuthenticationRequestsPage = () =>
    history.push(ROUTES.AUTHENTICATION_REQUESTS);

  return (
    <>
      <NavbarComponent color='white' light expand>
        <span className='sidebar-toggle d-flex mr-2' onClick={toggleSidebar}>
          <span className='d-sm-inline-block ml-n2 align-self-center'>
            <Hamburger
              className='icon-tertiary cursor-pointer'
              width={35}
              height={35}
            />
          </span>
        </span>
        {matchProductsPath &&
          (tag && id ? (
            <span
              className={cs('main-text text-overflow-wrap ml-2', {
                highlighted: productInstance?.nicknameHighlighted,
              })}
            >
              {productInstance.nickname ||
                t('sharedComponents.itemDetails').toUpperCase()}
            </span>
          ) : (
            <span className='main-text ml-2'>
              {t('sharedComponents.productDetails').toUpperCase()}
            </span>
          ))}
        {matchAssembliesPath &&
          (instanceId && id ? (
            <span
              className={cs('main-text text-overflow-wrap ml-2', {
                highlighted: assemblyInstance?.nicknameHighlighted,
              })}
            >
              {assemblyInstance.nickname ||
                t('sharedComponents.itemDetails').toUpperCase()}
            </span>
          ) : (
            <span className='main-tex ml-2t'>
              {t('sharedComponents.assemblyDetails').toUpperCase()}
            </span>
          ))}
        {(matchBoxPath || matchOrderBoxPath) && (
          <span className='main-text ml-2'>
            {t('components.box').toUpperCase()}
          </span>
        )}
        {matchOrderPath && !matchOrderBoxPath && (
          <span className='main-text ml-2'>
            {t('components.orderDetails').toUpperCase()}
          </span>
        )}
        {!!breadcrumbs.length && (
          <BreadcrumbsHeader data={breadcrumbs} className='pl-2 w-80' />
        )}
        <Collapse navbar>
          <Nav className='ml-auto' navbar>
            <div className='d-flex align-items-center'>
              {permissions.includes(PERMISSIONS.LOG_DATA) && (
                <span className='d-sm-inline-block icon-spacing'>
                  <Logger />
                </span>
              )}
              {permissions.includes(PERMISSIONS.SCAN) &&
                permissions.includes(PERMISSIONS.WEB_CAMERA_SCAN) && (
                  <>
                    <span className='d-sm-inline-block icon-spacing'>
                      <Scan
                        className='cursor-pointer icon-tertiary icon-hover-secondary no-outline'
                        onClick={() => setIsScanModalOpen(true)}
                        width={40}
                        height={40}
                        id='scan-icon'
                      />
                    </span>
                    <BasicTooltip placement='bottom' target='scan-icon'>
                      {t('components.scanADataMatrix')}
                    </BasicTooltip>
                  </>
                )}
              {permissions.includes(PERMISSIONS.VIEW_SHARE) && (
                <>
                  <span className='d-sm-inline-block icon-spacing'>
                    <ShareEnvelop
                      className={`cursor-pointer icon-${
                        pathname === `${ROUTES.MESSAGES}`
                          ? 'secondary'
                          : 'tertiary'
                      } no-outline`}
                      width={40}
                      height={40}
                      onClick={() => {
                        history.push(`${ROUTES.MESSAGES}`);
                      }}
                      id='share-icon'
                    />
                  </span>
                  <BasicTooltip placement='bottom' target='share-icon'>
                    {t('modal.shareAProduct')}
                  </BasicTooltip>
                </>
              )}
              {permissions.includes(PERMISSIONS.SEND_FEEDBACK) && (
                <>
                  <span className='d-sm-inline-block icon-spacing'>
                    <Feedback
                      className='cursor-pointer icon-tertiary icon-hover-secondary no-outline'
                      onClick={() => setIsFeedbackModalOpened(true)}
                      width={38}
                      height={38}
                      id='feedback-icon'
                    />
                  </span>
                  <BasicTooltip placement='bottom' target='feedback-icon'>
                    {t('components.sendFeedback')}
                  </BasicTooltip>
                </>
              )}
              {permissions.includes(PERMISSIONS.VIEW_AUTHENTICATION_REQUESTS) &&
                permissions.includes(PERMISSIONS.READ_COUNTERFEIT) && (
                  <>
                    <span className='d-sm-inline-block icon-spacing'>
                      <WarningIcon
                        className={`cursor-pointer icon-hover-secondary no-outline icon-${
                          isRouteActive(
                            history.location.pathname,
                            ROUTES.AUTHENTICATION_REQUESTS
                          )
                            ? 'secondary'
                            : 'tertiary'
                        }`}
                        onClick={openProductAuthenticationRequestsPage}
                        width={38}
                        height={38}
                        id='counterfeit-icon'
                      />
                    </span>
                    <BasicTooltip placement='bottom' target='counterfeit-icon'>
                      {t('containers.authenticationRequests')}
                    </BasicTooltip>
                  </>
                )}
              {permissions.includes(PERMISSIONS.NOTIFICATION_ALL) && (
                <>
                  <span className='d-sm-inline-block icon-spacing'>
                    <Dropdown
                      nav
                      inNavbar
                      isOpen={notificationsOpen}
                      toggle={handleNotificationToggle}
                    >
                      <DropdownToggle
                        className='p-0 cursor-pointer'
                        tag='div'
                        data-toggle='dropdown'
                      >
                        <NotificationBell
                          className={`cursor-pointer icon-hover-secondary no-outline icon-${
                            notificationsOpen ? 'secondary' : 'tertiary'
                          }`}
                          width={40}
                          height={40}
                          id='notification-icon'
                        />
                        {!!unreadCount && (
                          <span
                            className={cs('notification-badge', {
                              'notification-badge-long':
                                unreadCount > MAX_NOTIFICATION_NUMBER,
                            })}
                          >
                            {getNotificationNumber(unreadCount)}
                          </span>
                        )}
                      </DropdownToggle>
                      <DropdownMenu right className='mt-2 border-0'>
                        <div className='notification-dropdown'>
                          <Notifications
                            isOpen={notificationsOpen}
                            closeNotifications={handleNotificationToggle}
                            handleOpenNotificationSettings={
                              handleOpenNotificationSettings
                            }
                          />
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  </span>
                  <BasicTooltip placement='bottom' target='notification-icon'>
                    {t('components.notifications')}
                  </BasicTooltip>
                </>
              )}
              <span className='d-sm-inline-block icon-spacing-left'>
                <Dropdown
                  className='d-flex'
                  nav
                  inNavbar
                  isOpen={dropdownOpen}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                >
                  <DropdownToggle
                    className='p-0 cursor-pointer'
                    nav
                    tag='div'
                    aria-expanded={dropdownOpen}
                    data-toggle='dropdown'
                  >
                    <Profile
                      className={`cursor-pointer icon-hover-secondary no-outline icon-${
                        dropdownOpen || pathname === `${ROUTES.PROFILE}`
                          ? 'secondary'
                          : 'tertiary'
                      }`}
                      width={40}
                      height={40}
                      onClick={() => setLanguagesExpanded(false)}
                      id='profile-icon'
                    />
                  </DropdownToggle>
                  <DropdownMenu right className='mt-2'>
                    <DropdownItem
                      onClick={() => history.push(`${ROUTES.PROFILE}`)}
                    >
                      {t('components.profile')}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => history.push(`${ROUTES.SETTINGS}`)}
                    >
                      {t('button.settings')}
                    </DropdownItem>
                    <div
                      className='cursor-pointer dropdown-item'
                      onClick={() => {
                        setLanguagesExpanded(!languagesExpanded);
                      }}
                    >
                      {t('components.language')}
                    </div>
                    <Collapse
                      isOpen={languagesExpanded}
                      onClick={() => setLanguagesExpanded(!languagesExpanded)}
                    >
                      {INTERNATIONALIZATION.map((lang) =>
                        renderLanguages(lang)
                      )}
                    </Collapse>
                    <DropdownItem onClick={() => logoutWithRedirect()}>
                      {t('components.logout')}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </span>
              <BasicTooltip placement='bottom' target='profile-icon'>
                {t('components.profile')}
              </BasicTooltip>
            </div>
          </Nav>
        </Collapse>
      </NavbarComponent>
      {isScanModalOpen && (
        <ScanModal
          isOpened={isScanModalOpen}
          handleClose={() => setIsScanModalOpen(false)}
        />
      )}
      {isFeedbackModalOpened && (
        <FeedbackModal
          isOpened={isFeedbackModalOpened}
          handleClose={() => setIsFeedbackModalOpened(false)}
        />
      )}
    </>
  );
};

Navbar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  productInstance: PropTypes.object.isRequired,
  assemblyInstance: PropTypes.object.isRequired,
  getUserSettings: PropTypes.func.isRequired,
  getUnreadNotificationsCount: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
  setNotificationConnectionStatus: PropTypes.func.isRequired,
  notificationsConnected: PropTypes.bool.isRequired,
  getServerMetadata: PropTypes.func.isRequired,
  serverMetadataFetched: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array,
  organization: PropTypes.object,
  language: PropTypes.string,
};

export default connect(
  ({ product, notification, user, application, assembly, breadcrumbs }) => ({
    productInstance: product.instance,
    assemblyInstance: assembly.instance,
    unreadCount: notification.unreadCount,
    notificationsConnected: notification.connected,
    serverMetadataFetched: application.serverMetadataFetched,
    breadcrumbs,
    organization: user.organization,
    language: user.language,
  }),
  {
    toggleSidebar,
    getUnreadNotificationsCount,
    setNotificationConnectionStatus,
    getUserSettings,
    getServerMetadata,
  }
)(Navbar);
