import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PillButton from './PillButton';

const Logger = ({ loggedData }) => {
  const downloadLogs = () => {
    const blob = new Blob([loggedData.join('\n\n')], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'logs.txt';
    link.click();
  };

  return <PillButton onClick={downloadLogs}>Download Logs</PillButton>;
};

Logger.propTypes = {
  loggedData: PropTypes.array,
};

export default connect(({ logger }) => ({ loggedData: logger.loggedData }))(
  Logger
);
