import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _debug from 'debug';
import { useHistory, useParams } from 'react-router-dom';
import { BarcodeScanner } from 'react-usb-barcode-scanner';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_ROUTE_TABS,
  ERROR_MESSAGES_READER,
  ERROR_MESSAGES_READER_RC,
  FIREBASE_EVENTS,
  ORGANIZATION_TYPE,
  PERMISSIONS,
  READER_STATES,
  ROUTES,
} from '../constants';
import {
  scanTag,
  setFetching,
  setScanning,
} from '../redux/actions/readerActions';
import {
  getTag,
  setEmptyTag,
  setError,
  setOccupiedTag,
} from '../redux/actions/tagActions';
import SyncTag from './Modals/ProductModals/SyncTag';
import { handleAnalytics } from '../utils/analytics';
import { removeModalOpenClass } from '../utils/domManipulation';
import { ErrorModal, WarningModal } from './Modals/Shared';
import { generateQueryString } from '../utils/tabs';
import { navigateWithState } from '../utils/routes';
import AddToolToBoxInInspectionModal from './Modals/ReconditioningModals/AddToolToBoxInInspectionModal';
import { fetchProductByDataMatrix } from '../client/tag';
import { getPermissions } from '../utils/user';

const debug = _debug('Bridge:Reader');

const Reader = ({
  state,
  organizationDetails,
  scannedTag,
  scanTag,
  getTag,
  setEmptyTag,
  setOccupiedTag,
  setScanning,
  setError,
  setFetching,
  orderDetails,
}) => {
  const [syncTagModalOpen, setSyncTagModalOpen] = useState(false);
  const [
    tagIsOutsideOfCurrentBoxModalOpen,
    setTagIsOutsideOfCurrentBoxModalOpen,
  ] = useState(false);
  const [
    isAddToolOnInspectionScreenModalOpened,
    setIsAddToolOnInspectionScreenModalOpened,
  ] = useState(false);
  const [
    isToolFromAnotherOrganizationWarningModalOpened,
    setIsToolFromAnotherOrganizationWarningModalOpened,
  ] = useState(false);
  const [
    isThisTagIsNotAvailableInTheToolsLibraryModalOpened,
    setIsThisTagIsNotAvailableInTheToolsLibraryModalOpened,
  ] = useState(false);

  const [scannedTagData, setScannedTagData] = useState({});
  const [scannedDataMatrixTagData, setScannedDataMatrixTagData] = useState({});
  const [
    addingToolToBoxInInspectionFromBrandsDatabase,
    setAddingToolToBoxInInspectionFromBrandsDatabase,
  ] = useState(false);

  const history = useHistory();
  const permissions = getPermissions();
  const { boxId } = useParams();
  const { t } = useTranslation();

  // Remove tag from the store because remounting passes old scanTag
  const clean = () => scanTag(null);

  const handleCloseSyncTagModal = () => {
    setEmptyTag(null);
    scanTag(null);
    setSyncTagModalOpen(false);
  };

  const handleTagOnCustomerSide = (tagInfo) => {
    if (tagInfo.boxId && !tagInfo.productInstanceId) {
      handleAnalytics(FIREBASE_EVENTS.BOX_VIEW_FROM_SCAN);
      if (tagInfo.orderId) {
        history.push(
          `${ROUTES.RECONDITIONING_ORDER}/${tagInfo.orderId}/box/${tagInfo.boxId}`
        );
      } else {
        history.push(`${ROUTES.RECONDITIONING_BOX}/${tagInfo.boxId}`);
      }
    } else if (tagInfo.assemblyInstanceId && tagInfo.productInstanceId) {
      handleAnalytics(FIREBASE_EVENTS.ASSEMBLY_INSTANCE_VIEW_FROM_SCAN);
      history.push({
        pathname: `${ROUTES.DATABASE_ASSEMBLIES}/${tagInfo.assemblyId}/instance/${tagInfo.assemblyInstanceId}/product/${tagInfo.productId}/tag/${scannedTag}`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_ASSEMBLIES]
        ),
      });
    } else if (tagInfo.assemblyInstanceId && !tagInfo.productInstanceId) {
      handleAnalytics(FIREBASE_EVENTS.ASSEMBLY_VIEW_FROM_SCAN);
      history.push({
        pathname: `${ROUTES.DATABASE_ASSEMBLIES}/${tagInfo.assemblyId}/instance/${tagInfo.assemblyInstanceId}/tag/${scannedTag}`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_ASSEMBLIES]
        ),
      });
    } else {
      handleAnalytics(FIREBASE_EVENTS.PRODUCT_INSTANCE_VIEW_FROM_SCAN);
      history.push({
        pathname: `${ROUTES.DATABASE_PRODUCTS}/${tagInfo.productId}/tag/${scannedTag}`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_PRODUCTS]
        ),
      });
    }
  };

  const handleOpenTagOnReconCenterSide = (tagInfo, scanAgain = false) => {
    // scanAgain is used in case of scanning on inspection or verification
    // after page of scanned tag is opened to update Qty

    if (tagInfo.productInstanceId && tagInfo.boxId && tagInfo.orderId) {
      handleAnalytics(FIREBASE_EVENTS.RC_SCAN_PRODUCT);

      navigateWithState(
        history,
        `${ROUTES.RC}/order/${tagInfo.orderId}/box/${tagInfo.boxId}`,
        scanAgain
          ? {
              tagToScan: scannedTagData.tag,
            }
          : { highlightedInstanceId: tagInfo.productInstanceId }
      );
    } else if (!tagInfo.productInstanceId && tagInfo.boxId && tagInfo.orderId) {
      handleAnalytics(FIREBASE_EVENTS.RC_SCAN_BOX);

      navigateWithState(history, `${ROUTES.RC}/order/${tagInfo.orderId}`, {
        highlightedBoxId: tagInfo.boxId,
      });
    } else if (tagInfo.productInstanceId && tagInfo.productId) {
      handleAnalytics(FIREBASE_EVENTS.RC_SCAN_PRODUCT_NOT_IN_ANY_ORDER);
      history.push({
        pathname: `${ROUTES.DATABASE_PRODUCTS}/${tagInfo.productId}/tag/${
          scannedTag || scannedTagData.tag
        }`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_PRODUCTS]
        ),
      });
    } else {
      setError(ERROR_MESSAGES_READER_RC.UNABLE_TO_OPEN);
      handleAnalytics(FIREBASE_EVENTS.RC_SCAN_TAG_DOESNT_EXIST);
    }

    setScannedTagData({});
  };

  const handleTagOnReconCenterSide = (tagInfo, scannedTag) => {
    if (
      state === READER_STATES.SCAN_ON_INSPECTION ||
      state === READER_STATES.SCAN_ON_VERIFICATION
    ) {
      if (tagInfo.boxId !== boxId && tagInfo.orderId) {
        setScannedTagData({ ...tagInfo, tag: scannedTag });
        setTagIsOutsideOfCurrentBoxModalOpen(true);
        handleAnalytics(
          FIREBASE_EVENTS.RC_SCANNED_TAG_OUTSIDE_BOX_WHILE_INSPECTION
        );
      } else if (
        !tagInfo.orderId &&
        orderDetails.organizationId === tagInfo.organizationId
      ) {
        setScannedTagData({ ...tagInfo, tag: scannedTag });
        setIsAddToolOnInspectionScreenModalOpened(true);
      } else if (
        !tagInfo.orderId &&
        orderDetails.organizationId !== tagInfo.organizationId
      ) {
        setScannedTagData({ ...tagInfo, tag: scannedTag });
        setIsToolFromAnotherOrganizationWarningModalOpened(true);
      } else {
        handleOpenTagOnReconCenterSide(tagInfo);
      }
    } else {
      handleOpenTagOnReconCenterSide(tagInfo);
    }
  };

  const handleDataMatrixOnInspectionScreen = async () => {
    try {
      const { data } = await fetchProductByDataMatrix({
        params: {
          code: scannedTag,
        },
      });

      if (data) {
        setScannedDataMatrixTagData({
          ...data,
          tag: scannedTag,
        });
        setAddingToolToBoxInInspectionFromBrandsDatabase(true);
        setIsAddToolOnInspectionScreenModalOpened(true);
      }
    } catch (e) {
      if (e.response?.status === 404) {
        setIsThisTagIsNotAvailableInTheToolsLibraryModalOpened(true);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (!scannedTag) {
        return;
      }

      switch (state) {
        case READER_STATES.GLOBAL:
        case READER_STATES.SCAN_ON_INSPECTION:
        case READER_STATES.SCAN_ON_VERIFICATION:
          if (state === READER_STATES.SCAN_ON_INSPECTION) {
            handleAnalytics(
              FIREBASE_EVENTS.RC_PRODUCT_SCANNED_DURING_INSPECTION
            );
          }
          const isOrganizationReconCenter =
            organizationDetails.type === ORGANIZATION_TYPE.RECON_CENTER;

          try {
            setError({
              title: '',
              subtitle: '',
            });
            setSyncTagModalOpen(false);
            setFetching(true);
            const tagInfo = await getTag(scannedTag, isOrganizationReconCenter);
            setFetching(false);
            clean();
            if (isOrganizationReconCenter) {
              handleTagOnReconCenterSide(tagInfo, scannedTag);
            } else {
              handleTagOnCustomerSide(tagInfo);
            }
          } catch (e) {
            removeModalOpenClass();
            setFetching(false);
            if (e.response?.status === 404) {
              if (isOrganizationReconCenter) {
                if (state === READER_STATES.SCAN_ON_INSPECTION) {
                  handleDataMatrixOnInspectionScreen();
                } else {
                  clean();
                  setError(ERROR_MESSAGES_READER_RC.EMPTY_TAG);
                }
              } else {
                setSyncTagModalOpen(true);
              }
            }
            if (e.response?.status === 403 || e.response?.status === 400) {
              clean();
              // Tag is in another group
              setError(
                Object.values(ERROR_MESSAGES_READER).find(
                  (error) => error.message === e.response.data.message
                )
              );
            }
          }
          setFetching(null);
          return;
        case READER_STATES.CREATE_ASSEMBLY:
        case READER_STATES.SYNC_ASSEMBLY:
        case READER_STATES.SYNC_NEW_TAG_TO_PRODUCT_INSTANCE:
          try {
            setScanning(true);
            const tagInfo = await getTag(scannedTag);
            clean();
            setOccupiedTag({ ...tagInfo, scannedTag });
            setScanning(false);
          } catch (e) {
            setScanning(false);
            clean();
            if (e.response && e.response.status === 404) {
              // Empty tag
              setEmptyTag(scannedTag);
            } else if (e.response && e.response.status === 403) {
              // occupied tag from the group of which user is not a member
              setOccupiedTag({ isScannedTagInUserGroup: false, scannedTag });
            }
          }
          return;
        case READER_STATES.SYNC_PRODUCT:
          try {
            setScanning(true);
            const tagInfo = await getTag(scannedTag);
            setOccupiedTag({ ...tagInfo, scannedTag });
            setScanning(false);
            clean();
          } catch (e) {
            setScanning(false);
            clean();
            if (e.response && e.response.status === 404) {
              // Empty tag
              setEmptyTag(scannedTag);
            } else if (e.response && e.response.status === 403) {
              // occupied tag from the group of which user is not a member
              setOccupiedTag({ isScannedTagInUserGroup: false, scannedTag });
            }
          }
          return;
        case READER_STATES.SYNC_BOX:
          try {
            setScanning(true);
            const tagInfo = await getTag(scannedTag);
            setOccupiedTag({ ...tagInfo, scannedTag });
            setScanning(false);
            clean();
          } catch (e) {
            setScanning(false);
            clean();
            if (e.response && e.response.status === 404) {
              // Empty tag
              setEmptyTag(scannedTag);
            } else if (e.response && e.response.status === 403) {
              // occupied tag from the group of which user is not a member
              setOccupiedTag({ isScannedTagInUserGroup: false, scannedTag });
            }
          }
          return;
        case READER_STATES.ADD_MESSAGE:
          try {
            setScanning(true);
            setError('');
            const tagInfo = await getTag(scannedTag);
            if (tagInfo.assemblyId) {
              setError(ERROR_MESSAGES_READER.NO_PRODUCT_FOUND);
            } else {
              setOccupiedTag({ ...tagInfo, scannedTag });
            }
          } catch (e) {
            if (e.response) {
              if (e.response.status === 403) {
                setError(ERROR_MESSAGES_READER.UNABLE_TO_SCAN);
              }
              if (e.response.status === 404) {
                setError(ERROR_MESSAGES_READER.NO_PRODUCT_FOUND);
              }
            }
          } finally {
            setScanning(false);
            clean();
          }
          return;
        default:
          debug(`Case ${state} not handled.`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedTag]);

  return (
    <>
      {permissions.includes(PERMISSIONS.SCAN) && (
        <BarcodeScanner
          config={{
            intervalBetweenKeyPress: 100,
            scanningEndTimeout: 200,
            debug: true,
            ignoreOnInputs: true,
          }}
        />
      )}
      {syncTagModalOpen && (
        <SyncTag
          isOpened={syncTagModalOpen}
          handleClose={handleCloseSyncTagModal}
        />
      )}
      {tagIsOutsideOfCurrentBoxModalOpen && (
        <WarningModal
          title={t('modal.thisTagIsNotPartOfThisOrder')}
          text1={t(
            state === READER_STATES.SCAN_ON_INSPECTION
              ? 'modal.doYouWantToCancelTheOngoingInspectionProcessAndNavigateToTheCorrespondingOrder'
              : 'modal.doYouWantToCancelTheOngoingVerificationProcessAndNavigateToTheCorrespondingOrder'
          )}
          leftButtonContent={t('button.no')}
          rightButtonContent={t('button.yes')}
          handleClickLeftButton={() => {
            setScannedTagData({});
            setTagIsOutsideOfCurrentBoxModalOpen(false);
          }}
          handleClickRightButton={() => {
            handleOpenTagOnReconCenterSide(scannedTagData, true);
            setTagIsOutsideOfCurrentBoxModalOpen(false);
          }}
        />
      )}
      {isToolFromAnotherOrganizationWarningModalOpened && (
        <WarningModal
          handleClose={() =>
            setIsToolFromAnotherOrganizationWarningModalOpened(false)
          }
          title={t(
            'modal.theToolIsNotConnectedToThisReconditioningOrderAndIsThePropertyOfAnotherOrganization'
          )}
          text1={t(
            'modal.itAppearsThatSeveralOrdersMightHaveBeenMixedUpDuringTheInspectionProcessPleaseTakeAMomentToReviewThisOrderCarefullyIfYouNeedAnyFurtherAssistanceDoNotHesitateToContactTheAppropriateSalesOrganization'
          )}
          text2={t(
            'modal.forAdditionalInformationAboutThisProductPleaseClickTheMoreDetailsButtonBelow'
          )}
          rightButtonContent={t('button.moreDetails')}
          handleClickRightButton={() => {
            handleOpenTagOnReconCenterSide(scannedTagData, true);
            setTagIsOutsideOfCurrentBoxModalOpen(false);
          }}
          withoutLeftButton
        />
      )}
      {isThisTagIsNotAvailableInTheToolsLibraryModalOpened && (
        <WarningModal
          handleClose={() =>
            setIsThisTagIsNotAvailableInTheToolsLibraryModalOpened(false)
          }
          text1={t('error.thisTagIsNotAvailableInTheToolsLibrary')}
          text2={t(
            'modal.theFunctionalityYouAreLookingForIsCurrentlyInDevelopmentAndWillBeAvailableSoonStayTunedForUpdatesAndThankYouForYourPatience'
          )}
          rightButtonContent={t('button.cancel')}
          handleClickRightButton={() =>
            setIsThisTagIsNotAvailableInTheToolsLibraryModalOpened(false)
          }
          withoutLeftButton
        />
      )}
      {isAddToolOnInspectionScreenModalOpened && (
        <AddToolToBoxInInspectionModal
          handleClose={() => {
            setIsAddToolOnInspectionScreenModalOpened(false);
            clean();
          }}
          scannedTagData={scannedTagData}
          handleOpenTagOnReconCenterSide={handleOpenTagOnReconCenterSide}
          setTagIsOutsideOfCurrentBoxModalOpen={
            setTagIsOutsideOfCurrentBoxModalOpen
          }
          addingToolFromBrandsDatabase={
            addingToolToBoxInInspectionFromBrandsDatabase
          }
          scannedDataMatrixTagData={scannedDataMatrixTagData}
        />
      )}
      <ErrorModal />
    </>
  );
};

Reader.propTypes = {
  state: PropTypes.string.isRequired,
  organizationDetails: PropTypes.object,
  orderDetails: PropTypes.object.isRequired,
  scannedTag: PropTypes.string,
  scanTag: PropTypes.func,
  getTag: PropTypes.func,
  setEmptyTag: PropTypes.func.isRequired,
  setScanning: PropTypes.func.isRequired,
  setOccupiedTag: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setFetching: PropTypes.func,
};

export default connect(
  ({ reader, user, order }) => ({
    state: reader.state,
    scannedTag: reader.scannedTag,
    codeType: reader.codeType,
    organizationDetails: user.organization,
    orderDetails: order.details,
  }),
  {
    scanTag,
    getTag,
    setEmptyTag,
    setScanning,
    setFetching,
    setOccupiedTag,
    setError,
  }
)(Reader);
