import { BARCODE_SCANNED, BARCODE_SCANNING } from 'react-usb-barcode-scanner';
import { scanTag } from '../actions/readerActions';
import { isUrlFormat } from '../../utils/validations';
import { openUrlInNewTab } from '../../utils/shared';
import { blurFocusedElementBeforeScanning } from '../../utils/domManipulation';
import { handleAnalytics } from '../../utils/analytics';
import { FIREBASE_EVENTS } from '../../constants';

const barcodeScannerMiddleware = (store) => (next) => (action) => {
  if (action.type === BARCODE_SCANNING) {
    if (store.getState().reader.ignoreFocusedElement) {
      // Blur the currently focused element
      // so scanned data will go through default flow
      blurFocusedElementBeforeScanning();
    }
  }

  if (action.type === BARCODE_SCANNED) {
    const barcode = action.payload.data.trim();
    if (barcode.length > 3) {
      handleAnalytics(FIREBASE_EVENTS.SCANNED_CODE);

      if (isUrlFormat(barcode)) {
        openUrlInNewTab(barcode);
        return;
      }
      store.dispatch(scanTag(barcode));
    }
  }

  return next(action);
};

export default barcodeScannerMiddleware;
