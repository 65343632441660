import React, { useState } from 'react';

import PropTypes from 'prop-types';
import _debug from 'debug';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Modal from '../Modal';
import { NewLoading, NewModalStep } from '../Shared';
import { ReactComponent as WarningIcon } from '../../../assets/icon/ic_warning.svg';
import { ROUTES } from '../../../constants';
import PlaceInBoxBoxesTable from '../../ReconditioningComponents/PlaceInBoxBoxesTable';
import { getBoxes, setBoxes } from '../../../redux/actions/boxActions';
import { CustomInfiniteScroll } from '../../Shared';
import { getMappedErrorMessage } from '../../../utils/error';
import { postBoxProductInstances } from '../../../client/box';

const debug = _debug('Bridge:PlaceInBoxModal');

const STEPS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

const PlaceInBoxModal = ({
  isOpened = true,
  handleClose,
  getBoxes,
  setBoxes,
  boxes,
  instanceId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [step, setStep] = useState(STEPS.INITIAL);
  const [error, setError] = useState(STEPS.INITIAL);

  const handleLoadBoxes = async (props) =>
    getBoxes({
      IsActive: true,
      ProductInstanceId: instanceId,
      ...props,
    });

  const handleAddProductInstanceToTheBox = async (boxId) => {
    try {
      setStep(STEPS.LOADING);
      await postBoxProductInstances(boxId, [instanceId]);
      history.push(`${ROUTES.RECONDITIONING_BOX}/${boxId}`, {
        highlightedProductInstanceId: instanceId,
      });
    } catch (e) {
      const response = getMappedErrorMessage(e);

      setError(response);

      debug(`Couldn't add product instance to the box. Reason: ${e}`);
      setStep(STEPS.ERROR);
    }
  };

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return (
          <NewModalStep
            title={t('modal.placeInBox')}
            subtitle={t('modal.youCanPlaceTheProductInOneOfTheAvailableBoxes')}
            handleCancel={handleClose}
            bodyClassName='h-75'
            isCenteredVertically
            isCenteredHorizontally
          >
            <div className='mt-5 d-flex h-100'>
              <CustomInfiniteScroll
                handleLoadMoreData={handleLoadBoxes}
                resetData={() => setBoxes([])}
                data={boxes}
              >
                <PlaceInBoxBoxesTable
                  boxes={boxes}
                  handleRowClick={handleAddProductInstanceToTheBox}
                />
              </CustomInfiniteScroll>
            </div>
          </NewModalStep>
        );
      case STEPS.LOADING:
        return <NewLoading />;
      case STEPS.ERROR:
        return (
          <NewModalStep
            handleCancel={handleClose}
            isCenteredVertically
            bodyClassName='d-flex flex-column text-txt-primary pb-5 justify-content-center'
            rightButtonContent={t('button.cancel')}
            handleClickRightButton={handleClose}
          >
            <WarningIcon className='warning-icon icon-danger mb-5' />
            <div className='mb-5'>
              {t(error) || t('error.somethingWentWrong')}
            </div>
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };

  return (
    <Modal
      className='add-box-modal d-flex flex-column justify-content-between p-0'
      isOpened={isOpened}
    >
      {renderStep()}
    </Modal>
  );
};

PlaceInBoxModal.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  getBoxes: PropTypes.func.isRequired,
  setBoxes: PropTypes.func.isRequired,
  boxes: PropTypes.array,
  instanceId: PropTypes.string,
};

export default connect(
  ({ box }) => ({
    boxes: box.boxes,
  }),
  {
    getBoxes,
    setBoxes,
  }
)(PlaceInBoxModal);
