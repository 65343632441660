import React from 'react';

import ColumnResizer from 'react-column-resizer';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import { PRODUCTS_TABLE_HEADER_MIN_WIDTH } from '../../constants';
import TriangleDownIcon from './TriangleDownIcon';
import TriangleUpIcon from './TriangleUp';

const TableView = ({
  columns,
  tableRows,
  onSortChange,
  sortColumn,
  sortOrder,
  isModal,
  tableClassName = 'resizable-table',
  headerClassName = 'new-resizable-table-header',
}) => {
  const { t } = useTranslation();

  const renderTriangleDownIcon = (name) => (
    <TriangleDownIcon onClick={() => onSortChange(name)} className='pl-2' />
  );

  const renderTriangleUpIcon = (name) => (
    <TriangleUpIcon onClick={() => onSortChange(name)} className='pl-2' />
  );

  return (
    <table className={tableClassName}>
      <tbody>
        <tr className={headerClassName}>
          {/* Empty header element is used as a placeholder for dropdown arrow in product items */}
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          {columns.map(
            ({ name, value, isResizable, sort, className, isInitial }) => {
              return (
                <>
                  <th
                    key={value}
                    className={cs(
                      `new-resizable-table-column-title ${className}`,
                      {
                        'new-resizable-table-padding': isInitial,
                        'resizable-table-product-name-header-initial-width':
                          isInitial,
                      }
                    )}
                  >
                    {t(name)}
                    {sort &&
                      (name === sortColumn
                        ? sortOrder === '_asc'
                          ? renderTriangleUpIcon(name)
                          : renderTriangleDownIcon(name)
                        : renderTriangleUpIcon(name))}
                  </th>
                  {isResizable && (
                    <ColumnResizer
                      className='resizable-table-resizer universal-resizable-table-resizer'
                      minWidth={PRODUCTS_TABLE_HEADER_MIN_WIDTH}
                    />
                  )}
                </>
              );
            }
          )}
        </tr>
        {isModal && (
          <tr className='universal-resizable-table-space-row'>
            <td colSpan={columns.length + 1} />
          </tr>
        )}
        {tableRows}
      </tbody>
    </table>
  );
};

TableView.propTypes = {
  columns: PropTypes.array.isRequired,
  tableRows: PropTypes.element.isRequired,
  isModal: PropTypes.bool,
  tableClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  onSortChange: PropTypes.func,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.string,
};

export default TableView;
