import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.counterfeitRisk, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests:
          payload.page === 1
            ? [...payload.data]
            : [...state.requests, ...payload.data],
      };
    case types.SET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: payload.data,
      };
    case types.SET_COUNTERFEIT_RISK_HIGHLIGHTED_IDS_SUCCESS:
      return {
        ...state,
        highlightedIds: [...payload.highlightedIds],
      };
    case types.SEND_COUNTERFEIT_REQUEST_SUCCESS:
      return {
        ...state,
        requests: [payload.data, ...state.requests],
      };
    case types.SET_COUNTERFEIT_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload.data,
      };
    default:
      return state;
  }
}
