import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import _get from 'lodash.get';
import _debug from 'debug';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { toast } from 'react-toastify';
import cs from 'classnames';

import {
  clearProductsExternal,
  clearError,
  createProduct,
  getProductsSecoTools,
  getProductsUnitedTools,
  getProductsWalter,
  getProductsCoromant,
  getProductsDormerPramet,
  highlightProductDetails,
  transferTag,
} from '../../redux/actions/productActions';
import { getGroups } from '../../redux/actions/groupActions';
import { syncProduct } from '../../redux/actions/tagActions';
import { handleAnalytics } from '../../utils/analytics';
import { getMappedErrorMessage } from '../../utils/error';
import { NewLoading, NewModalStep, Error } from '../Modals/Shared';
import {
  LinearLoader,
  HorizontalLine,
  SearchInput,
  ToastWrapper,
  Image,
} from '../Shared';
import IdemCatalogueTableView from './IdemCatalogueTableView';
import { ReactComponent as ProductAddedIcon } from '../../assets/icon/img-product-created-frame-w-product.svg';
import { ReactComponent as StarsIcon } from '../../assets/img/stars.svg';
import { ReactComponent as TriangleIcon } from '../../assets/icon/ic_triangle.svg';
import { DEFAULT_ROUTE_TABS, FIREBASE_EVENTS, ROUTES } from '../../constants';
import { getCurrentLocation } from '../../utils/geolocation';
import { isSearchTermLongEnough, scrollToTop } from '../../utils/shared';
import { generateQueryString } from '../../utils/tabs';
import { useSearch } from '../../hooks/Shared';
import { fetchProducts } from '../../client/product';

const debug = _debug('Bridge:CreateProduct');

const STEPS = {
  INITIAL: 'INITIAL',
  CREATE_FAILED: 'CREATE_FAILED',
  PRODUCT_EXISTS: 'PRODUCT_EXISTS',
  CREATING: 'CREATING',
  CREATED: 'CREATED',
  SYNCED: 'SYNCED',
};

const CreateProduct = ({
  goBack,
  isSyncable = false,
  isTransferOptionActive = false,
  handleClose,
  selectedGroup,
  selectedProduct: preselectedProduct,
  secoToolsProducts,
  unitedToolsProducts,
  walterProducts,
  coromantProducts,
  dormerPrametProducts,
  createProduct,
  clearProductsExternal,
  clearError,
  getProductsSecoTools,
  getProductsUnitedTools,
  getProductsWalter,
  getProductsCoromant,
  getProductsDormerPramet,
  openBridgeProducts = () => {},
  providerError,
  emptyTag,
  syncProduct,
  transferTag,
  highlightProductDetails,
}) => {
  const { t } = useTranslation();
  const { tag } = useParams();
  const history = useHistory();

  const [step, setStep] = useState(
    preselectedProduct ? STEPS.CREATING : STEPS.INITIAL
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([
    ...coromantProducts,
    ...dormerPrametProducts,
    ...secoToolsProducts,
    ...walterProducts,
    ...unitedToolsProducts,
  ]);
  const [createdProductId, setCreatedProductId] = useState();
  const [highlightedData, setHighlightedData] = useState();
  const { search, setSearch, debouncedSearch } = useSearch();
  const [productExistsInSelectedGroup, setProductExistsInSelectedGroup] =
    useState(false);

  const handleCancelModal = () => {
    if (isTransferOptionActive) {
      history.push({
        pathname: `${ROUTES.DATABASE_PRODUCTS}/${createdProductId}/tag/${tag}`,
        search: generateQueryString(
          DEFAULT_ROUTE_TABS[ROUTES.DATABASE_PRODUCTS]
        ),
      });
    }
    clearProductsExternal();
    handleClose();

    if (highlightedData) {
      highlightProductDetails(highlightedData);
      // scroll to top of the database page so the created product is in the view
      scrollToTop();
    }
  };

  const handleCreate = async ({
    externalId,
    dataProviderId,
    nickname,
    name,
    imageUrl,
    code,
  }) => {
    try {
      setStep(STEPS.CREATING);
      setProductExistsInSelectedGroup(false);
      setSelectedProduct({ name, imageUrl });
      let productId;
      if (isSyncable) {
        const {
          data: { data },
        } = await fetchProducts({
          FilterGroups: selectedGroup?.id,
          FilterProductCode: code,
        });

        if (data.length) {
          productId = data[0].id;
          setProductExistsInSelectedGroup(true);
        }
      }
      if (!productId) {
        const { productId: id, highlightedData } = await createProduct({
          groupId: selectedGroup.id,
          externalId,
          nickname,
          dataProviderId,
          currentRouterLocation: history.location,
        });
        productId = id;

        setHighlightedData(highlightedData);
      }

      if (isSyncable) {
        const { highlightedData } = await syncProduct(emptyTag, productId);
        setHighlightedData(highlightedData);
        setStep(STEPS.SYNCED);
        return;
      }

      if (isTransferOptionActive) {
        try {
          const { lat, lng, accuracy } = await getCurrentLocation();
          await transferTag({
            tag,
            productId,
            positionLatitude: lat,
            positionLongitude: lng,
            positionAccuracy: accuracy,
          });
          setCreatedProductId(productId);
          handleAnalytics(FIREBASE_EVENTS.PRODUCT_INSTANCE_TRANSFER_TAG);
          setStep(STEPS.SYNCED);
          return;
        } catch (e) {
          // swallow error
        }
      }

      openBridgeProducts();
      setStep(STEPS.CREATED);
    } catch (e) {
      debug(
        `Create product failed. Reason: ${_get(e, 'response.data.error', e)}`
      );
      const response = getMappedErrorMessage(e);
      const responseStatus = e?.response?.status;

      switch (responseStatus) {
        case 409:
          setError('modal.productMustBeUnique');
          setStep(STEPS.CREATE_FAILED);
          break;
        case 504:
        case 500:
          setError('error.dataProviderFailed');
          setStep(STEPS.CREATE_FAILED);
          break;
        default:
          setError(response);
          setStep(STEPS.CREATE_FAILED);
      }
    }
  };

  useEffect(() => {
    if (preselectedProduct) {
      handleCreate(preselectedProduct);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preselectedProduct]);

  useEffect(() => {
    if (providerError) {
      setLoading(false);
      toast(t(providerError), {
        closeButton: false,
        onClose: clearError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerError]);

  useEffect(() => {
    if (
      secoToolsProducts ||
      unitedToolsProducts ||
      walterProducts ||
      coromantProducts ||
      dormerPrametProducts
    ) {
      setProducts([
        ...coromantProducts,
        ...dormerPrametProducts,
        ...secoToolsProducts,
        ...walterProducts,
        ...unitedToolsProducts,
      ]);
    }
    if (
      secoToolsProducts &&
      unitedToolsProducts &&
      walterProducts &&
      coromantProducts &&
      dormerPrametProducts
    ) {
      setLoading(false);
    }
  }, [
    secoToolsProducts,
    unitedToolsProducts,
    walterProducts,
    coromantProducts,
    dormerPrametProducts,
  ]);

  useEffect(() => {
    (async () => {
      if (step === STEPS.INITIAL && isSearchTermLongEnough(search)) {
        setLoading(true);
        getProductsSecoTools({ search });
        getProductsWalter({ search });
        getProductsUnitedTools({ search });
        getProductsCoromant({ search });
        getProductsDormerPramet({ search });
      } else {
        clearProductsExternal();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, step]);

  const renderStep = () => {
    switch (step) {
      case STEPS.INITIAL:
        return (
          <NewModalStep
            title={t('modal.addProduct')}
            subtitle={t(
              'modal.findAndDownloadProductInformationFromTheCatalog'
            )}
            leftButtonContent={t('button.back')}
            handleClickLeftButton={goBack}
            handleCancel={handleClose}
            headerChildren={
              <div className='d-flex flex-column align-items-center search-input-margin'>
                <SearchInput
                  className='w-75'
                  value={search}
                  setValue={setSearch}
                  placeholder={t('modal.searchInCatalog')}
                />
                {!!search && (
                  <HorizontalLine
                    childClassName={cs({
                      'font-weight-bold': search && !loading,
                    })}
                  >
                    {loading ? (
                      t('sharedComponents.searching')
                    ) : (
                      <Trans
                        i18nKey='components.result'
                        count={products.length}
                        values={{ count: products.length }}
                      />
                    )}
                  </HorizontalLine>
                )}
              </div>
            }
            bodyClassName='d-flex flex-column'
          >
            <>
              <ToastWrapper />
              {!!products.length && (
                <IdemCatalogueTableView
                  data={products}
                  handleCreateIdemCatalogueProduct={handleCreate}
                />
              )}
              {loading && <LinearLoader loaderClassName='mt-auto' />}
            </>
          </NewModalStep>
        );
      case STEPS.CREATING:
        return <NewLoading value={t('sharedComponents.creating')} />;
      case STEPS.CREATED:
        return (
          <NewModalStep
            handleCancel={handleCancelModal}
            handleClickRightButton={handleCancelModal}
            rightButtonContent={t('button.done')}
          >
            <Row className='m-0 h-100 d-flex align-items-center'>
              <Col className='p-0'>
                <Row className='m-0'>
                  <Col className='p-0 text-center position-relative'>
                    {selectedProduct.imageUrl && (
                      <Image
                        src={selectedProduct.imageUrl}
                        width={70}
                        height={70}
                        className='image-object-fit-cover border border-tertiary rounded-circle position-absolute image-position'
                      />
                    )}
                    {selectedProduct.imageUrl ? (
                      <StarsIcon
                        className='icon-secondary'
                        width={200}
                        height={175}
                      />
                    ) : (
                      <ProductAddedIcon
                        className='created-stars-icon'
                        width={200}
                        height={175}
                      />
                    )}
                  </Col>
                </Row>
                <Row className='m-0'>
                  <Col sm={{ offset: 2, size: 8 }} className='p-0 text-center'>
                    <Trans
                      i18nKey='modal.productNameAddedToGroupName'
                      values={{
                        name: selectedProduct.name,
                        groupName: selectedGroup?.name,
                      }}
                      components={[
                        <span className='font-weight-bolder text-break' />,
                        <br />,
                        <span className='font-weight-bolder' />,
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </NewModalStep>
        );
      case STEPS.CREATE_FAILED:
        return (
          <NewModalStep
            title={t('components.weCouldNotCreateYourProduct')}
            handleCancel={handleCancelModal}
            handleClickRightButton={handleCancelModal}
            rightButtonContent={t('button.done')}
            bodyClassName='d-flex flex-column align-items-center justify-content-center'
          >
            <TriangleIcon
              className='icon-danger mb-4'
              width={200}
              height={175}
            />
            <div>{t(error)}</div>
          </NewModalStep>
        );
      case STEPS.PRODUCT_EXISTS:
        return (
          <Error
            title='components.weCouldNotCreateYourProduct'
            subtitle='modal.productMustBeUnique'
            handleClose={handleCancelModal}
            handleTryAgain={() => {
              setSearch('');
              setSelectedProduct(null);
              setStep(STEPS.INITIAL);
            }}
            tryAgainButtonText='components.tryAgain'
          />
        );
      case STEPS.SYNCED:
        return (
          <NewModalStep
            rightButtonContent={t('button.done')}
            handleClickRightButton={handleCancelModal}
            handleCancel={handleCancelModal}
            bodyClassName='d-flex flex-column justify-content-center align-items-center mb-5'
          >
            <Image
              src={selectedProduct.imageUrl}
              width={96}
              height={96}
              className='rounded-circle border border-tertiary'
              containerClassName='mb-4'
              hasBadge
            />
            <h1>{t('modal.productSynced')}</h1>
            <div className='text-center mx-2 mt-2 text-break'>
              <Trans
                i18nKey={
                  productExistsInSelectedGroup && isSyncable
                    ? 'modal.youHaveSyncedTheTagToTheProduct'
                    : 'modal.newProductWasCreatedAndSyncedWithTheTag'
                }
                values={{
                  name: selectedProduct.name,
                }}
              />
            </div>
          </NewModalStep>
        );
      default:
        debug(`Case ${step} not handled.`);
        return null;
    }
  };
  return renderStep();
};

CreateProduct.propTypes = {
  groups: PropTypes.array.isRequired,
  secoToolsProducts: PropTypes.array.isRequired,
  unitedToolsProducts: PropTypes.array.isRequired,
  walterProducts: PropTypes.array.isRequired,
  coromantProducts: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  isSyncable: PropTypes.bool,
  isTransferOptionActive: PropTypes.bool,
  getGroups: PropTypes.func.isRequired,
  createProduct: PropTypes.func.isRequired,
  getProductsSecoTools: PropTypes.func.isRequired,
  getProductsUnitedTools: PropTypes.func.isRequired,
  getProductsWalter: PropTypes.func,
  getProductsCoromant: PropTypes.func,
  clearProductsExternal: PropTypes.func.isRequired,
  highlightProductDetails: PropTypes.func,
  openBridgeProducts: PropTypes.func,
  clearError: PropTypes.func.isRequired,
  emptyTag: PropTypes.string,
  syncProduct: PropTypes.func,
};

export default connect(
  ({ product, tag }) => ({
    providerError: product.providerError,
    secoToolsProducts: product.secoToolsProducts,
    unitedToolsProducts: product.unitedToolsProducts,
    walterProducts: product.walterProducts,
    coromantProducts: product.coromantProducts,
    dormerPrametProducts: product.dormerPrametProducts,
    emptyTag: tag.emptyTag,
  }),
  {
    createProduct,
    getProductsSecoTools,
    getProductsUnitedTools,
    getProductsWalter,
    getProductsCoromant,
    getProductsDormerPramet,
    clearProductsExternal,
    clearError,
    getGroups,
    highlightProductDetails,
    syncProduct,
    transferTag,
  }
)(CreateProduct);
