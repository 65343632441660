import React, { useState } from 'react';

import PropTypes from 'prop-types';
import Img from 'react-image';

import { ReactComponent as DataMatrixIcon } from '../../assets/icon/ic_datamatrix.svg';
import { extractExtension } from '../../utils/file';
import { getProductImage } from '../../utils/images';
import { SUPPORTED_IMAGE_EXTENSIONS } from './constants';
import { DEFAULT_IMAGE_FALLBACK } from '../../constants';

const Image = ({
  src,
  width,
  height,
  className = '',
  containerClassName = '',
  onClick = () => {},
  hasBadge = false,
  badgePosition = 'bottom-right',
  badgeSize = 'lg',
  badge = (
    <DataMatrixIcon
      className={`icon-secondary image-badge ${badgePosition} ${badgeSize}`}
      width={40}
      height={40}
    />
  ),
  ...props
}) => {
  const [fetchedSrc, setFetchedSrc] = useState(src);

  const getSrc = async (src) => {
    if (!src || SUPPORTED_IMAGE_EXTENSIONS.includes(extractExtension(src))) {
      return src;
    }
    try {
      const imgResponse = await getProductImage(src);
      return imgResponse;
    } catch {
      return null;
    }
  };

  useState(() => {
    (async () => {
      setFetchedSrc(await getSrc(src));
    })();
  }, [src]);

  const renderImage = () => (
    <Img
      src={[fetchedSrc, DEFAULT_IMAGE_FALLBACK]}
      loader={
        <Img src={DEFAULT_IMAGE_FALLBACK} width={width} height={height} />
      }
      width={width}
      height={height}
      // if image should't be scaled down - use image-object-fit-scale-down class conditionally
      className={`bg-white image-object-fit-scale-down ${className}`}
      onClick={onClick}
      {...props}
    />
  );

  return hasBadge ? (
    <div className={`image-container ${containerClassName}`}>
      {renderImage()}
      {badge}
    </div>
  ) : (
    renderImage()
  );
};

Image.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  badge: PropTypes.element,
  hasBadge: PropTypes.bool,
  badgePosition: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-right',
    'bottom-left',
  ]),
  badgeSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
};

export default Image;
