import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NotificationItem from './NotificationItem';
import {
  getNotifications,
  markAllNotificationsAsRead,
  updateNotificationStatus,
} from '../../redux/actions/notificationActions';
import {
  FIREBASE_EVENTS,
  HORIZONTAL_TAB_QUERY,
  NOTIFICATION_ACTION,
  NOTIFICATION_OBJECT_TYPE,
  NOTIFICATION_STATUS,
  ORGANIZATION_TYPE,
  ROUTES,
  USER_THREAD_STATUS,
  VERTICAL_TAB_QUERY,
} from '../../constants';
import { fetchProductInstanceById } from '../../client/product';
import { fetchThreadDetails } from '../../client/thread';
import { formatDate } from '../../utils/date';
import { getGeneralNotificationSetting } from '../../utils/notifications';
import { Loader } from '../Shared';
import NotificationError from '../Modals/NotificationModals/NotificationError';
import { ReactComponent as SettingsIcon } from '../../assets/icon/new-icons-ic-settings-blue.svg';
import { TAB_LABELS } from '../../containers/ProductContainers/const';
import { fetchAssemblyInstance } from '../../client/assembly';
import { generateQueryString } from '../../utils/tabs';
import { handleAnalytics } from '../../utils/analytics';

const Notifications = ({
  getNotifications,
  closeNotifications,
  notifications,
  organizationType,
  count,
  isOpen,
  settings,
  markAllNotificationsAsRead,
  updateNotificationStatus,
}) => {
  // Changing keys force react to crate new element due to inability
  // to reset current page on react infinite scroll component
  // Math.random is sufficient for this use
  const [resetNotificationDropdownKey, setResetNotificationDropdownKey] =
    useState(Math.random());
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [notificationError, setNotificationError] = useState({ open: false });

  const handleLoadNotifications = async (page) => {
    await getNotifications({ page });
  };

  useEffect(() => {
    (async () => {
      if (isOpen) {
        setLoading(true);
        setResetNotificationDropdownKey(Math.random());
        await getNotifications({ initial: true });
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleUpdateRouterHistory = ({ pathname, search, locationState }) =>
    history.push(
      {
        pathname,
        search,
      },
      {
        ...locationState,
      }
    );

  const handleOpenThreadsNotification = (pathname, objectId) =>
    handleUpdateRouterHistory({
      pathname,
      search: generateQueryString({
        [HORIZONTAL_TAB_QUERY]: TAB_LABELS.ITEM_DETAILS,
        [VERTICAL_TAB_QUERY]: TAB_LABELS.NOTES,
      }),
      locationState: {
        viewedThreadId: objectId,
      },
    });

  const handleNotificationClick = async ({
    objectType,
    action,
    objectId,
    id,
    parentType,
    parentId,
  }) => {
    await updateNotificationStatus(id, NOTIFICATION_STATUS.READ);
    switch (objectType) {
      case NOTIFICATION_OBJECT_TYPE.THREAD:
        try {
          const { data } = await fetchThreadDetails(objectId);
          if (
            action === NOTIFICATION_ACTION.COMPLETE ||
            data?.status === USER_THREAD_STATUS.DONE
          ) {
            setNotificationError({
              open: true,
              title: 'modal.alreadyHandled',
              description: 'modal.thisNoteHasBeenHandled',
            });
            return;
          }
          if (data.productInstanceId) {
            handleAnalytics(
              FIREBASE_EVENTS.PRODUCT_INSTANCE_VIEW_FROM_NOTIFICATION
            );

            return handleOpenThreadsNotification(
              `${ROUTES.DATABASE_PRODUCTS}/${data.productId}/tag/${data.tag}`,
              objectId
            );
          }
          if (data.productId) {
            return handleOpenThreadsNotification(
              `${ROUTES.DATABASE_PRODUCTS}/${data.productId}`,
              objectId
            );
          }
          if (data.assemblyInstanceId) {
            return handleOpenThreadsNotification(
              `${ROUTES.DATABASE_ASSEMBLIES}/${data.assemblyId}/instance/${data.assemblyInstanceId}/tag/${data.tag}`,
              objectId
            );
          }
          if (data.assemblyId) {
            return handleOpenThreadsNotification(
              `${ROUTES.DATABASE_ASSEMBLIES}/${data.assemblyId}`,
              objectId
            );
          }
        } catch (error) {
          // Swallow error
        }
        return;
      case NOTIFICATION_OBJECT_TYPE.RECONDITION_ORDER:
        if (organizationType === ORGANIZATION_TYPE.CUSTOMER) {
          history.push(`${ROUTES.RECONDITIONING_ORDER}/${objectId}`);
        } else {
          history.push(`${ROUTES.RC}/order/${objectId}`);
        }
        handleAnalytics(FIREBASE_EVENTS.ORDER_VIEW_FROM_NOTIFICATION);
        return;
      case NOTIFICATION_OBJECT_TYPE.AUTHENTICATION_REQUEST:
        if (organizationType === ORGANIZATION_TYPE.CUSTOMER) {
          history.push(`${ROUTES.AUTHENTICATION_REQUESTS}/${objectId}`);
        } else {
          history.push(`${ROUTES.LEGAL}/request/${objectId}`);
        }
        handleAnalytics(FIREBASE_EVENTS.REQUEST_VIEW_FROM_NOTIFICATION);
        return;
      case NOTIFICATION_OBJECT_TYPE.CONDITION_RATING:
      case NOTIFICATION_OBJECT_TYPE.USAGE_TIME:
      case NOTIFICATION_OBJECT_TYPE.TARGET_TIME:
        const notificationProperty =
          objectType === NOTIFICATION_OBJECT_TYPE.CONDITION_RATING
            ? { notificationConditionRatingId: objectId }
            : { notificationTimeTrackerId: objectId };
        try {
          if (parentType === 'ProductInstance') {
            const { data } = await fetchProductInstanceById(parentId);
            if (data.productId && data.tag) {
              handleAnalytics(
                FIREBASE_EVENTS.PRODUCT_INSTANCE_VIEW_FROM_NOTIFICATION
              );

              return handleUpdateRouterHistory({
                pathname: `${ROUTES.DATABASE_PRODUCTS}/${data.productId}/tag/${data.tag}`,
                search: generateQueryString({
                  [HORIZONTAL_TAB_QUERY]: TAB_LABELS.ITEM_DETAILS,
                  [VERTICAL_TAB_QUERY]:
                    objectType === NOTIFICATION_OBJECT_TYPE.CONDITION_RATING
                      ? TAB_LABELS.CONDITION_RATINGS
                      : TAB_LABELS.TIME_TRACKER,
                }),
                locationState: notificationProperty,
              });
            }
          } else {
            const { data } = await fetchAssemblyInstance(parentId);
            return history.push({
              pathname: `${ROUTES.DATABASE_ASSEMBLIES}/${data.assemblyId}/instance/${data.id}/tag/${data.assemblyTag}`,
              search: generateQueryString({
                [HORIZONTAL_TAB_QUERY]: TAB_LABELS.ITEM_DETAILS,
                [VERTICAL_TAB_QUERY]:
                  objectType === NOTIFICATION_OBJECT_TYPE.CONDITION_RATING
                    ? TAB_LABELS.CONDITION_RATINGS
                    : TAB_LABELS.TIME_TRACKER,
              }),
              locationState: notificationProperty,
            });
          }
        } catch (error) {
          setNotificationError({
            open: true,
            title:
              parentType === 'ProductInstance'
                ? 'modal.productItemDeleted'
                : 'modal.assemblyItemDeleted',
            description:
              parentType === 'ProductInstance'
                ? 'modal.thisProductItemHasBeenDeleted'
                : 'modal.thisAssemblyItemHasBeenDeleted',
          });
        }
        return;
      default:
        console.log('Unknown object type');
    }
  };

  const onMarkAllAsReadClick = async () => {
    await markAllNotificationsAsRead();
    handleAnalytics(FIREBASE_EVENTS.NOTIFICATIONS_MARK_ALL_ITEMS);
  };

  const handleStatusChange = async (e, id, oldStatus) => {
    e.preventDefault();
    e.stopPropagation();
    await updateNotificationStatus(
      id,
      oldStatus === NOTIFICATION_STATUS.READ
        ? NOTIFICATION_STATUS.UNREAD
        : NOTIFICATION_STATUS.READ
    );
  };
  const allNotificationsSetting = getGeneralNotificationSetting(
    settings.notification
  );

  return (
    <>
      <div className='d-flex justify-content-between py-2 border-bottom notification-header'>
        <div className='d-flex align-items-center pl-3 notification-title'>
          {t('components.notifications').toUpperCase()}
        </div>
        <p
          className='d-flex align-items-center pr-3 notification-icons'
          onClick={() =>
            history.push(`${ROUTES.SETTINGS}`, {
              activeTab: 1,
            })
          }
        >
          <SettingsIcon className='notification-settings icon-white' />
          <SettingsIcon className='notification-settings-active icon-secondary' />
        </p>
      </div>
      <div className='d-flex justify-content-between notification-recent'>
        <p>{t('components.recent')}</p>
        <p
          className='notification-mark-all cursor-pointer'
          onClick={onMarkAllAsReadClick}
        >
          {t('components.markAllAsRead')}
        </p>
      </div>
      {loading ? (
        <Loader key={0} className='py-3 w-100' />
      ) : (
        <div
          key={resetNotificationDropdownKey}
          className='overflow-auto notification-infinite-scroll'
        >
          <InfiniteScroll
            useWindow={false}
            pageStart={1}
            loadMore={handleLoadNotifications}
            hasMore={notifications.length < count}
            loader={
              <div key={0}>
                <Loader className='py-3 w-100' />
              </div>
            }
            className='mb-2'
          >
            {notifications.map(
              ({
                id,
                title,
                createdByEmail,
                createdByGivenName,
                createdByFamilyName,
                createdAt,
                objectType,
                objectId,
                action,
                status,
                parentType,
                parentId,
              }) => (
                <div
                  key={id}
                  className='notification-item-container cursor-pointer'
                >
                  <NotificationItem
                    id={id}
                    date={formatDate(createdAt)}
                    name={
                      createdByFamilyName || createdByGivenName
                        ? `${createdByGivenName} ${createdByFamilyName}`
                        : createdByEmail || ''
                    }
                    action={action}
                    objectType={objectType}
                    title={title}
                    usersOrganizationType={organizationType}
                    handleClick={async () => {
                      await handleNotificationClick({
                        objectType,
                        action,
                        objectId,
                        id,
                        parentType,
                        parentId,
                      });

                      closeNotifications();
                    }}
                    status={status || NOTIFICATION_STATUS.READ}
                    settings={settings}
                    allNotificationsSetting={allNotificationsSetting}
                    toggleStatus={(e) => handleStatusChange(e, id, status)}
                  />
                </div>
              )
            )}
          </InfiniteScroll>
        </div>
      )}
      <NotificationError
        isOpened={notificationError.open}
        title={notificationError.title}
        subtitle={notificationError.description}
        handleClose={() => setNotificationError({ open: false })}
      />
    </>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  closeNotifications: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  markAllNotificationsAsRead: PropTypes.func.isRequired,
  updateNotificationStatus: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  organizationType: PropTypes.string,
};

export default connect(
  ({ notification, user }) => ({
    notifications: notification.notifications,
    count: notification.rowCount,
    settings: user.settings,
    organizationType: user.organization.type,
  }),
  {
    getNotifications,
    markAllNotificationsAsRead,
    updateNotificationStatus,
  }
)(Notifications);
