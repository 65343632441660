import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { ENV, FIREBASE_CONFIG } from '../config';
import { PRODUCTION_ENVIRONMENT } from '../constants';
import { getUserConsent } from './cookies';

let analytics;

export const logToFirebase = (eventName, organizationId) => {
  logEvent(analytics, eventName, {
    organization: organizationId,
  });
};

export const handleAnalytics = (eventName) => {
  if (ENV === PRODUCTION_ENVIRONMENT) {
    const consent = getUserConsent();
    if (!consent?.analytic) {
      return;
    }

    const organizationId = localStorage.getItem('organizationId');

    logToFirebase(eventName, organizationId);
  }
};

export const initializeFirebaseAnalytics = () => {
  if (ENV === PRODUCTION_ENVIRONMENT) {
    const consent = getUserConsent();

    if (consent?.analytic) {
      const app = initializeApp(FIREBASE_CONFIG);
      analytics = getAnalytics(app);
    }
  }
};
