import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.logger, action) {
  const { type, payload } = action;

  switch (type) {
    case types.LOG_SUCCESS:
      return {
        ...state,
        loggedData: [...state.loggedData, payload.data],
      };
    default:
      return state;
  }
}
