import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { PERMISSIONS, ROUTES } from '../../constants';
import { StickyButton } from '../Shared';
import ReconditioningDetails from '../ReconditioningComponents/ReconditioningDetails';
import { getPermissions } from '../../utils/user';
import PlaceInBoxModal from '../Modals/ReconditioningModals/PlaceInBoxModal';
import { scrollToHighestElement } from '../../utils/shared';

const ReconditioningTab = ({ instance, details }) => {
  const [isPlaceInBoxModalOpened, setIsPlaceInBoxModalOpened] = useState(false);
  const [highlightedIds, setHighlightedIds] = useState([]);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const permissions = getPermissions();

  useEffect(() => {
    if (location.state && location.state.highlightedIds) {
      setHighlightedIds(location.state.highlightedIds);

      history.replace({
        ...location,
        state: {
          ...location.state,
          highlightedIds: undefined,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (highlightedIds?.length) {
      scrollToHighestElement(highlightedIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedIds]);

  const handleOpenBox = () =>
    history.push(`${ROUTES.RECONDITIONING_BOX}/${instance.boxId}`, {
      highlightedProductInstanceId: instance.id,
    });

  const renderComponent = () => {
    if (details.reconditionId) {
      return (
        <div className='reconditioning-tab h-100 mb-5'>
          <h4 className='text-txt-primary font-weight-bold mb-3'>
            {t('modal.reconditionDetails')}
          </h4>

          <ReconditioningDetails
            highlightedIds={highlightedIds}
            setHighlightedIds={setHighlightedIds}
          />
          <div className='sticky-buttons-right'>
            {instance.boxId ? (
              <StickyButton className='mr-5' onClick={() => handleOpenBox()}>
                {t('components.viewInBox')}
              </StickyButton>
            ) : (
              permissions.includes(PERMISSIONS.ADD_PRODUCT_TO_BOX) && (
                <StickyButton
                  className='mr-5'
                  onClick={() => setIsPlaceInBoxModalOpened(true)}
                >
                  {t('modal.placeInBox')}
                </StickyButton>
              )
            )}
          </div>
        </div>
      );
    }

    return (
      <div className='font-size-md'>
        {t('modal.thereIsNoReconditioningDataForThisProduct')}
      </div>
    );
  };

  return (
    <>
      {renderComponent()}
      {isPlaceInBoxModalOpened && (
        <PlaceInBoxModal
          handleClose={() => setIsPlaceInBoxModalOpened(false)}
          instanceId={instance.id}
        />
      )}
    </>
  );
};

ReconditioningTab.propTypes = {
  instance: PropTypes.object,
  details: PropTypes.object,
};

export default connect(({ product }) => ({
  instance: product.instance,
  details: product.details,
}))(ReconditioningTab);
