import React from 'react';

import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cs from 'classnames';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Img from 'react-image';
import { version } from '../../package.json';
import { getUsersSidebarRoutes } from '../utils/sidebarRoutes';
import { sidebarRoutes } from '../routes/sidebarRoutes';
import { removeTrailingSlashes, isRouteActive } from '../utils/routes';
import { BRAND_LOGOS_SIDEBAR, PERMISSIONS } from '../constants';
import { getPermissions } from '../utils/user';

const Sidebar = ({ isSidebarOpened, breadcrumbs, dev, brand }) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const history = useHistory();
  const usersSidebarRoutes = getUsersSidebarRoutes(sidebarRoutes);
  const permissions = getPermissions();

  const isRouteInBreadcrumbs = (name) =>
    breadcrumbs.length && breadcrumbs[0].label === name;

  const checkIsRouteActive = (pathname, path, additionalPaths, name) =>
    isRouteActive(pathname, path, additionalPaths, name) ||
    isRouteInBreadcrumbs(name);

  return (
    <nav
      className={`sidebar sidebar-sticky ${!isSidebarOpened ? ' toggled' : ''}`}
    >
      <div className='sidebar-content'>
        <div className='d-flex justify-content-center mt-2'>
          <Img width={170} height={46} src={BRAND_LOGOS_SIDEBAR[brand?.id]} />
        </div>
        <PerfectScrollbar className='d-flex flex-column flex-grow-1'>
          <Container
            className={cs('flex-grow-1 mt-5 pb-2 d-flex flex-column', {
              'justify-content-between': usersSidebarRoutes.length >= 4,
            })}
          >
            {usersSidebarRoutes.map(
              ({
                name,
                path,
                search,
                icon,
                iconActive,
                additionalPaths = [],
              }) => (
                <Row
                  key={name}
                  className={cs('text-center flex-grow-1', {
                    'align-items-start': usersSidebarRoutes.length < 4,
                  })}
                >
                  <Col className='d-flex justify-content-center w-100'>
                    <div className='d-flex flex-column align-items-center justify-content-center px-2 pb-3'>
                      <div
                        className={`d-flex justify-content-center align-items-center p-2 sidebar-badge cursor-pointer ${
                          checkIsRouteActive(
                            pathname,
                            path,
                            additionalPaths,
                            name
                          )
                            ? 'color-active'
                            : 'text-white'
                        }`}
                        onClick={() =>
                          removeTrailingSlashes(history.location.pathname) !==
                            path && history.push({ pathname: path, search })
                        }
                      >
                        {checkIsRouteActive(
                          pathname,
                          path,
                          additionalPaths,
                          name
                        )
                          ? iconActive
                          : icon}
                      </div>
                      <p className='text-txt-tertiary pt-2'>{t(name)}</p>
                    </div>
                  </Col>
                </Row>
              )
            )}
          </Container>
        </PerfectScrollbar>
        {permissions.includes(PERMISSIONS.VIEW_SIDEBAR_DESCRIPTION) && (
          <div className='d-flex flex-column align-items-center text-break text-txt-tertiary my-2 mx-1'>
            <span>{t('components.ideaExploration')}</span>
            <span>{t('components.forInternalUseOnly')}</span>
          </div>
        )}
        {dev && (
          <div className='w-100 text-center text-txt-tertiary'>
            {`${t('components.version')}: ${version}`}
          </div>
        )}
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  isSidebarOpened: PropTypes.bool,
  dev: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
  brand: PropTypes.object,
};

export default connect(({ sidebar, breadcrumbs, user }) => ({
  isSidebarOpened: sidebar.isOpen,
  breadcrumbs,
  brand: user.organization.brand,
}))(Sidebar);
