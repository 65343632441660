import React, { useEffect, useState, Fragment } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import Loader from '../Shared/Loader';
import { fetchProductInstanceHistory } from '../../client/tag';
import { formatDate } from '../../utils/date';
import { ReactComponent as SyncIcon } from '../../assets/icon/new-icons-ic-sync.svg';
import { ReactComponent as TransferTagIcon } from '../../assets/icon/new-icons-ic-transfer-tag.svg';
import { ReactComponent as ReaderIcon } from '../../assets/icon/new-icons-ic-reader.svg';
import Table from '../Shared/Table';
import { Map } from '../Shared';
import { handleAnalytics } from '../../utils/analytics';
import { FIREBASE_EVENTS } from '../../constants';

const ACTIONS = {
  Sync: {
    name: 'modal.sync',
    icon: <SyncIcon className='icon-secondary mr-2' height={36} width={36} />,
  },
  Scan: {
    name: 'modal.scan',
    icon: <ReaderIcon className='icon-secondary mr-2' height={36} width={36} />,
  },
  TransferTag: {
    name: 'modal.transferTag',
    icon: (
      <TransferTagIcon className='icon-secondary mr-2' height={36} width={36} />
    ),
  },
  SyncNewTag: {
    name: 'modal.syncNewTag',
    icon: <ReaderIcon className='icon-secondary mr-2' height={36} width={36} />,
  },
};

const InstanceHistoryTab = ({ groupId, instanceId, tag }) => {
  const [loading, setLoading] = useState(false);
  const [instanceHistory, setInstanceHistory] = useState([]);
  const { t } = useTranslation();

  const columns = [
    {
      name: t('components.name'),
      isResizable: true,
      style: { width: '25%' },
    },
    {
      name: t('sharedComponents.event'),
      value: 'action',
      isSortable: true,
      isResizable: true,
    },
    {
      name: t('components.date'),
      value: 'created_at',
      isSortable: true,
      style: { width: '20%' },
    },
  ];

  const onSort = async (columnValue, sortOrder) => {
    const { data } = await fetchProductInstanceHistory({
      FilterGroups: groupId,
      FilterProductInstances: instanceId,
      Sort: `${columnValue}${sortOrder}`,
    });
    setInstanceHistory(data.data);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await fetchProductInstanceHistory({
          FilterGroups: groupId,
          FilterProductInstances: instanceId,
        });
        handleAnalytics(FIREBASE_EVENTS.PRODUCT_INSTANCE_HISTORY_VIEW);
        setInstanceHistory(data.data);
        setLoading(false);
      } catch (e) {
        // Swallow error
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className='instance-history-tab h-100'>
      <Table
        isExpandable
        onSort={(columnValue, sortOrder) => onSort(columnValue, sortOrder)}
        expandData={instanceHistory?.map(
          ({ positionAccuracy, positionLatitude, positionLongitude }) => {
            const locations = [];
            if (positionAccuracy !== null && positionLongitude !== null) {
              locations.push({
                positionAccuracy,
                positionLongitude,
                positionLatitude,
                tag,
              });
            }
            return locations.length ? (
              <Map
                className='map-dimensions-inside-instance-history'
                locations={locations}
                selectedTag={tag}
                selectedAccuracy={positionAccuracy}
                selectedPosition={[positionLatitude, positionLongitude]}
              />
            ) : (
              <p className='mt-2 ml-4'>{t('components.noLocationData')}</p>
            );
          }
        )}
        columns={columns}
        data={instanceHistory?.map(
          ({ createdByGivenName, createdByFamilyName, action, createdAt }) => {
            return {
              rowColumns: [
                <Fragment key={createdAt}>
                  {ACTIONS[action]?.icon}
                  {createdByGivenName} {createdByFamilyName}
                </Fragment>,
                t(ACTIONS[action]?.name),
                formatDate(createdAt),
              ],
            };
          }
        )}
      />
    </div>
  );
};

InstanceHistoryTab.propTypes = {
  instanceId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
};

export default InstanceHistoryTab;
