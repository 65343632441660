import React from 'react';

import PropTypes from 'prop-types';
import Img from 'react-image';
import { Trans } from 'react-i18next';

import { ReactComponent as DataMatrixCircleIcon } from '../../../assets/icon/ic_datamatrix.svg';
import { DEFAULT_IMAGE_FALLBACK } from '../../../constants';

const NewSynced = ({
  name,
  image,
  text = 'modal.newProductWasCreatedAndSyncedWithTheTag',
}) => {
  return (
    <div className='h-100 d-flex flex-column justify-content-center'>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='header-image d-flex align-items-center justify-content-center'>
          <Img
            className='image-object-fit-cover border border-secondary'
            src={[image, DEFAULT_IMAGE_FALLBACK]}
            height={170}
            width={170}
          />
        </div>
        <DataMatrixCircleIcon
          className='tag-overlay icon-secondary'
          height={40}
          width={40}
        />
      </div>
      <div className='text-center pt-3'>
        <Trans
          i18nKey={text}
          values={{ name }}
          components={[<span className='font-weight-bolder' />]}
        />
      </div>
    </div>
  );
};

NewSynced.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default NewSynced;
