import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import _debug from 'debug';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

import { ReactComponent as SuccessIcon } from '../../assets/icon/ic-modal-ok.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icon/ic-modal-error.svg';
import { ReactComponent as PlusIcon } from '../../assets/icon/ic-add-blue-border.svg';
import { ReactComponent as TrashIcon } from '../../assets/icon/icons-ic-trash-red.svg';
import { ERROR_MESSAGES, MAX_NUMBER_OF_FILES_IN_THREAD } from '../../constants';
import { getMappedErrorMessage } from '../../utils/error';
import { getFileIcon } from '../../utils/images';
import {
  bytesToMB,
  isStringValid,
  scrollToElementWithId,
} from '../../utils/shared';
import { DeleteFileModal } from '../Modals/Shared';
import { BasicTooltip, Loader } from '.';
import { useFileDownload } from '../../hooks/Shared';

const debug = _debug('Bridge:UploadFiles');

const STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  DELETED: 'deleted',
};

const defaultUploadFile = async (file) => {
  return {
    data: file,
  };
};

const UploadFiles = ({
  files = [],
  setFiles,
  title,
  setAllFilesUploaded = () => {},
  resetErrorMessages = () => {},
  addErrorMessage = () => {},
  uploadFile = defaultUploadFile,
  maxNumberOfFiles = MAX_NUMBER_OF_FILES_IN_THREAD,
  invalidFileTypes,
  maxFileSizeInBytes,
  invalidFilenameCharacters,
  className = 'mb-5',
  readOnly = false,
  required = false,
}) => {
  const { t } = useTranslation();
  const { isDownloadLoading, downloadingFileId, downloadFile } =
    useFileDownload();

  const [allFilesWithStatus, setAllFilesWithStatus] = useState([]);
  const [isDeleteFileModalOpened, setIsDeleteFileModalOpened] = useState(false);
  const [fileToDeleteIndex, setFileToDeleteIndex] = useState(null);

  const componentTitle = title || t('components.files');

  useEffect(() => {
    if (!allFilesWithStatus.length) {
      setAllFilesWithStatus(
        files.map((file) => ({
          data: file,
          name: file.name,
          status: STATUS.SUCCESS,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (allFilesWithStatus.length) {
      setAllFilesUploaded(
        !allFilesWithStatus.find(({ status }) => status === STATUS.PENDING)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFilesWithStatus]);

  const filterFilesPossibleToUpload = (files) =>
    files.filter(({ name, size }) => {
      const extensionIndex = name.lastIndexOf('.');
      const fileType = extensionIndex > -1 ? name.substr(extensionIndex) : null;

      if (!isStringValid(name, invalidFilenameCharacters)) {
        addErrorMessage(
          t(ERROR_MESSAGES.FILE_NAME_HAS_INVALID_CHARACTERS, {
            name,
            invalidCharacters: invalidFilenameCharacters.toString(),
          })
        );

        return false;
      }

      if (size > maxFileSizeInBytes) {
        addErrorMessage(
          t(ERROR_MESSAGES.FILE_SIZE_EXCEEDED, {
            name,
            maxSize: bytesToMB(maxFileSizeInBytes),
          })
        );
        return false;
      }

      if (!size) {
        addErrorMessage(
          t(ERROR_MESSAGES.EMPTY_FILE, {
            name,
          })
        );
        return false;
      }

      if (!fileType || invalidFileTypes.includes(fileType?.toUpperCase())) {
        addErrorMessage(
          t(ERROR_MESSAGES.FILE_TYPE_NOT_SUPPORTED, { name, fileType })
        );
        return false;
      }

      return true;
    });

  const numberOfFiles = () =>
    allFilesWithStatus.filter(
      ({ status }) => status === STATUS.SUCCESS || status === STATUS.PENDING
    ).length;

  const handleUploadFiles = async (e) => {
    resetErrorMessages();
    const allFiles = [...e.target.files];
    let filteredFiles = filterFilesPossibleToUpload(allFiles);

    if (filteredFiles.length) {
      try {
        const currentLength = allFilesWithStatus.length;

        const currentNumberOfFiles = numberOfFiles();
        if (currentNumberOfFiles + filteredFiles.length > maxNumberOfFiles) {
          addErrorMessage(
            t(ERROR_MESSAGES.MAX_NUMBER_OF_FILES_EXCEEDED, {
              maxNumberOfFiles,
            })
          );

          // upload only first chunk of elements that fit inside max number of allowed files
          filteredFiles = filteredFiles.slice(
            0,
            maxNumberOfFiles - currentNumberOfFiles
          );
        }

        filteredFiles.forEach((file, index) => {
          setAllFilesWithStatus((prevState) => [
            ...prevState,
            { name: file.name, status: STATUS.PENDING },
          ]);

          uploadFile(file)
            .then((response) => {
              setAllFilesWithStatus((prevState) =>
                prevState.map((file, i) =>
                  i === currentLength + index
                    ? {
                        ...file,
                        data: response.data,
                        status: STATUS.SUCCESS,
                      }
                    : file
                )
              );

              setFiles((prevStateFiles) => [...prevStateFiles, response.data]);
            })
            .catch((error) => {
              setAllFilesWithStatus((prevState) =>
                prevState.map((file, i) =>
                  i === currentLength + index
                    ? { ...file, status: STATUS.ERROR, error }
                    : file
                )
              );

              addErrorMessage(
                `${file.name}: ${t(getMappedErrorMessage(error))}`
              );
            });
        });
        scrollToElementWithId('bottom-scroll-to-id');
      } catch (e) {
        debug(`Couldn't add some files to the thread. Reason: ${e}`);
      }
    }
    e.target.value = '';
  };

  const deleteFile = () => {
    setAllFilesWithStatus((prevState) =>
      prevState.map((file, i) =>
        fileToDeleteIndex === i ? { ...file, status: STATUS.DELETED } : file
      )
    );

    const fileData = allFilesWithStatus[fileToDeleteIndex].data;
    setFiles((prevState) =>
      prevState.filter((file) => !isEqual(file, fileData))
    );
  };

  const handleOpenDeleteFileModal = (index) => {
    setFileToDeleteIndex(index);
    setIsDeleteFileModalOpened(true);
  };

  const handleCloseDeleteFileModal = () => {
    setIsDeleteFileModalOpened(false);
    setFileToDeleteIndex(null);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case STATUS.PENDING:
        return <Loader size='sm' />;
      case STATUS.ERROR:
        return (
          <div>
            <ErrorIcon className='icon-danger' height={20} width={20} />
          </div>
        );
      case STATUS.SUCCESS:
        return (
          <div>
            <SuccessIcon className='icon-success' height={20} width={20} />
          </div>
        );
      default:
    }
  };

  const isFilesListEmpty = () =>
    !allFilesWithStatus.filter(({ status }) => status !== STATUS.DELETED)
      .length;

  return (
    !(readOnly && !files.length) && (
      <>
        <div className={`upload-files-container ${className}`}>
          <div
            id='files-section'
            className='d-flex align-items-center justify-content-between'
          >
            <div className={cs('', { 'font-weight-bold': readOnly })}>
              {componentTitle}
              {required && '*'}
            </div>
            {!readOnly && (
              <label htmlFor='files-upload' className='m-0'>
                <input
                  className='d-none'
                  type='file'
                  name='file'
                  id='files-upload'
                  multiple
                  onChange={handleUploadFiles}
                  disabled={numberOfFiles() === maxNumberOfFiles}
                />
                <div className='d-flex align-items-center'>
                  <div className='mr-2 font-size-sm'>{`${numberOfFiles()}/${maxNumberOfFiles}`}</div>
                  <PlusIcon
                    height={30}
                    width={30}
                    className={cs('circle-plus-icon add-file-plus-icon', {
                      'files-added':
                        !isFilesListEmpty() &&
                        numberOfFiles() !== maxNumberOfFiles,
                      disabled: numberOfFiles() === maxNumberOfFiles,
                    })}
                  />
                </div>
              </label>
            )}
          </div>
          {allFilesWithStatus.map((file, i) => {
            return (
              file.status !== STATUS.DELETED && (
                <div
                  key={file.name + i}
                  className='mt-2 d-flex align-items-center justify-content-between'
                >
                  <div
                    className={`d-flex align-items-center ${
                      readOnly ? 'w-100' : 'w-75'
                    }`}
                  >
                    {getFileIcon(file.name, 'sm')}
                    <p
                      className={cs('text-truncate my-0 mr-2', {
                        'disabled-file-name': file.status === STATUS.PENDING,
                        link: file.status === STATUS.SUCCESS,
                      })}
                      disabled={
                        file.status !== STATUS.SUCCESS &&
                        file.data?.id === downloadingFileId &&
                        isDownloadLoading
                      }
                      onClick={() => downloadFile(file.data)}
                    >
                      {file.name}
                    </p>
                    {!readOnly && getStatusIcon(file.status)}
                    {!!(
                      file.status === STATUS.SUCCESS &&
                      file.data?.id === downloadingFileId &&
                      isDownloadLoading
                    ) && <Loader size='sm' containerClassName='ml-2' />}
                  </div>
                  {!readOnly && (
                    <div id={`delete-file-icon-${i}`}>
                      <TrashIcon
                        className={cs(
                          'icon-danger hoverable delete-file-icon',
                          {
                            disabled: file.status === STATUS.PENDING,
                          }
                        )}
                        width={24}
                        height={24}
                        onClick={() => {
                          handleOpenDeleteFileModal(i);
                        }}
                        id={`trash-icon-${i}`}
                      />
                    </div>
                  )}
                  {file.status === STATUS.PENDING && (
                    <BasicTooltip target={`delete-file-icon-${i}`}>
                      {t('modal.youCanRemoveThisFileOnceItIsUploaded')}
                    </BasicTooltip>
                  )}
                </div>
              )
            );
          })}
        </div>
        {/* div below is the placeholder to scroll to the bottom so user can see the uploaded files */}
        <div className='mb-5' id='bottom-scroll-to-id' />
        {isDeleteFileModalOpened && (
          <DeleteFileModal
            isOpened={isDeleteFileModalOpened}
            handleClose={handleCloseDeleteFileModal}
            fileId={fileToDeleteIndex}
            handleDeleteFile={deleteFile}
          />
        )}
      </>
    )
  );
};

UploadFiles.propTypes = {
  title: PropTypes.string,
  files: PropTypes.array,
  setFiles: PropTypes.func.isRequired,
  setAllFilesUploaded: PropTypes.func,
  resetErrorMessages: PropTypes.func,
  addErrorMessage: PropTypes.func,
  uploadFile: PropTypes.func,
  maxNumberOfFiles: PropTypes.number,
  invalidFileTypes: PropTypes.array,
  maxFileSizeInBytes: PropTypes.number,
  invalidFilenameCharacters: PropTypes.array,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};

export default connect(({ application }) => ({
  invalidFileTypes: application.invalidFileTypes,
  maxFileSizeInBytes: application.maxFileSizeInBytes,
  invalidFilenameCharacters: application.invalidFilenameCharacters,
}))(UploadFiles);
