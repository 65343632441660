import React, { useEffect, useState } from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import PropTypes from 'prop-types';

import {
  HORIZONTAL_TAB_QUERY,
  ORIENTATION,
  VERTICAL_TAB_QUERY,
} from '../../constants';
import {
  doesTabExist,
  filterHiddenTabs,
  getSelectedVerticalTabElement,
  getTabIndex,
} from '../../utils/tabs';
import ViewOptions from './ViewOptions';
import { useQuery } from '../../hooks/Shared';

const TabsWrapper = ({
  data,
  className = '',
  tabListClassName = '',
  orientation = ORIENTATION.HORIZONTAL,
  selectedTabPanelClassName = '',
  withViewOptions = false,
  nested = false, // need to set to true if nested for handling query params
}) => {
  const [filteredData, setFilteredData] = useState(filterHiddenTabs(data));
  // URL query label for tab selected in this instance of tabs
  const queryTabLabel =
    orientation === ORIENTATION.HORIZONTAL
      ? HORIZONTAL_TAB_QUERY
      : VERTICAL_TAB_QUERY;
  const {
    query: selectedTabQuery,
    setQuery: setSelectedTabQuery,
    setMultipleQueries,
  } = useQuery(queryTabLabel);

  useEffect(() => {
    setFilteredData(filterHiddenTabs(data));
  }, [data]);

  useEffect(() => {
    const initialTab =
      (doesTabExist(filteredData, selectedTabQuery) && selectedTabQuery) ||
      filteredData[0]?.label;

    if (initialTab !== selectedTabQuery) {
      setSelectedTabQuery(initialTab, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getSelectedVerticalTabElement()) {
      getSelectedVerticalTabElement().scrollIntoView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabQuery]);

  const getTabIndexByLabel = (label) => getTabIndex(filteredData, label);

  const updateBothOrientationTabsQueries = (tabLabel) => {
    const { orientation: nestedOrientation, data: nestedTabs } =
      filteredData[getTabIndexByLabel(tabLabel)].component.props;

    const visibleNestedTabs = filterHiddenTabs(nestedTabs);

    setMultipleQueries([
      { label: orientation, value: tabLabel },
      { label: nestedOrientation, value: visibleNestedTabs[0].label },
    ]);
  };

  const handleTabChange = (tabLabel) => {
    if (nested) {
      updateBothOrientationTabsQueries(tabLabel);
    } else {
      setSelectedTabQuery(tabLabel);
    }
  };

  const renderTabsList = () => (
    <TabList
      className={`${
        orientation === ORIENTATION.HORIZONTAL
          ? 'horizontal-list'
          : 'vertical-list'
      } ${tabListClassName}`}
    >
      {filteredData.map(
        ({ option, optionSuffix, disabled, onClick }, index) => (
          <Tab disabled={disabled} key={index} onClick={onClick}>
            <div className='d-flex align-items-center'>
              <span>{option}</span> {optionSuffix}
            </div>
          </Tab>
        )
      )}
    </TabList>
  );

  return (
    !!selectedTabQuery && (
      <Tabs
        className={`${
          orientation === ORIENTATION.HORIZONTAL
            ? 'horizontal-tabs'
            : 'vertical-tabs'
        } ${className}`}
        selectedIndex={getTabIndexByLabel(selectedTabQuery)}
        onSelect={(index) => handleTabChange(filteredData[index].label)}
        selectedTabPanelClassName={`react-tabs__tab-panel--selected ${
          orientation === ORIENTATION.HORIZONTAL
            ? 'horizontal-tab-panel--selected'
            : 'vertical-tab-panel--selected'
        } ${selectedTabQuery} ${selectedTabPanelClassName}`}
      >
        {orientation === ORIENTATION.HORIZONTAL ? (
          <div className='horizontal-list-wrapper'>
            {renderTabsList()}

            {(withViewOptions ||
              filteredData[getTabIndexByLabel(selectedTabQuery)]
                ?.withViewOptions) && (
              <ViewOptions className='view-options pt-4 mt-3 pr-4' />
            )}
          </div>
        ) : (
          renderTabsList()
        )}

        {filteredData.map(({ component }, index) => (
          <TabPanel key={index}>{component}</TabPanel>
        ))}
      </Tabs>
    )
  );
};

export default TabsWrapper;

TabsWrapper.propTypes = {
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  tabListClassName: PropTypes.string,
  orientation: PropTypes.string,
  selectedTabPanelClassName: PropTypes.string,
  withViewOptions: PropTypes.bool,
  nested: PropTypes.bool,
};
