import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ReconditionRegrinds } from '../../assets/icon/ic-recondition-instance.svg';
import { isDefined } from '../../utils/shared';

const ICON_SIZES = {
  sm: 24,
  md: 27,
};

const InstanceReconditionColumn = ({
  reconditionCount,
  iconSize = 'md',
  className = '',
}) => {
  return (
    isDefined(reconditionCount) && (
      <div className={`instance-recondition-column mr-1 ${className}`}>
        <div>
          <ReconditionRegrinds
            className='recondition-instances-icon'
            height={ICON_SIZES[iconSize]}
            width={ICON_SIZES[iconSize]}
          />
        </div>
        <span className='recondition-label'>{reconditionCount}</span>
      </div>
    )
  );
};

InstanceReconditionColumn.propTypes = {
  reconditionCount: PropTypes.number,
  iconSize: PropTypes.oneOf(['sm', 'md']),
  className: PropTypes.string,
};

export default InstanceReconditionColumn;
