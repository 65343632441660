import React from 'react';
import PropTypes from 'prop-types';
import BasicTooltip from './BasicTootip';
import { THEME_COLORS_NAMES } from '../../constants';

const DivWithTooltip = ({
  parentContent = '',
  tooltipContent = parentContent,
  className = '',
  tooltipBgColor = THEME_COLORS_NAMES.SECONDARY,
  tooltipClassName = '',
  id,
  placement = 'top',
}) => (
  <>
    <div className={className} id={id}>
      {parentContent}
      <BasicTooltip
        placement={placement}
        target={id}
        bgColor={tooltipBgColor}
        className={tooltipClassName}
      >
        {tooltipContent}
      </BasicTooltip>
    </div>
  </>
);

DivWithTooltip.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.string,
  id: PropTypes.string.isRequired,
  parentContent: PropTypes.any,
  tooltipContent: PropTypes.any,
  tooltipBgColor: PropTypes.oneOf(Object.values(THEME_COLORS_NAMES)),
  tooltipClassName: PropTypes.string,
};

export default DivWithTooltip;
