import { combineReducers } from 'redux';
import { barcodeScannerReducer as barcodeScanner } from 'react-usb-barcode-scanner';

import auth from './reducers/authReducer';
import box from './reducers/boxReducer';
import order from './reducers/orderReducer';
import inspection from './reducers/inspectionReducer';
import sidebar from './reducers/sidebarReducer';
import group from './reducers/groupReducer';
import reader from './reducers/readerReducer';
import product from './reducers/productReducer';
import tag from './reducers/tagReducer';
import assembly from './reducers/assemblyReducer';
import counterfeitRisk from './reducers/counterfeitRiskReducer';
import user from './reducers/userReducer';
import supportMessage from './reducers/messagesReducer';
import application from './reducers/applicationReducer';
import notification from './reducers/notificationReducer';
import pagination from './reducers/paginationReducer';
import breadcrumbs from './reducers/breadcrumbsReducer';
import util from './reducers/utilReducer';
import logger from './reducers/loggerReducer';
import { USER_LOGOUT } from './constants';

const appReducer = combineReducers({
  auth,
  box,
  order,
  inspection,
  sidebar,
  group,
  reader,
  product,
  tag,
  assembly,
  counterfeitRisk,
  user,
  supportMessage,
  application,
  notification,
  barcodeScanner,
  pagination,
  breadcrumbs,
  util,
  logger,
});

const rootReducer = (state, action) => {
  return appReducer(action.type === USER_LOGOUT ? undefined : state, action);
};

export default rootReducer;
