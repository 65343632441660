export const STATUS_COLORS_NAMES = {
  TURQUOISE: 'status_turquoise',
  GREEN: 'status_green',
  YELLOW: 'status_yellow',
  BLUE: 'status_blue',
  GRAY: 'status_gray',
  ORANGE: 'status_orange',
  LIGHT_GRAY: 'status_light_gray',
  AQUA_BLUE: 'status_aqua_blue',
};

export const RECON_DASHBOARD_TAB_LABELS = {
  ORDERS: 'orders',
  BOXES: 'boxes',
  TOOLS: 'tools',
};

export const RECON_DASHBOARD_TAB_LABELS_TRANS = {
  ORDERS: 'containers.orders',
  BOXES: 'containers.boxes',
  TOOLS: 'containers.tools',
};

export const POST_REPORT_BOX_TAB_LABELS = {
  NRC: 'nrc',
  RC: 'rc',
  MISSING: 'missing',
};

export const POST_REPORT_BOX_TAB_LABELS_TRANS = {
  NRC: 'containers.nrc',
  RC: 'containers.reconditionable',
  MISSING: 'containers.missing',
};

export const CUSTOMER_ORDER_STATUSES = {
  PICKUP_REQUESTED: {
    KEY: 'PickUpRequested',
    LABEL: 'components.pickupRequested',
    COLOR: STATUS_COLORS_NAMES.AQUA_BLUE,
    IS_IN_RC: false,
  },
  SHIPPING_IN_PROGRESS: {
    KEY: 'ShippingInProgress',
    LABEL: 'components.shippingInProgress',
    COLOR: STATUS_COLORS_NAMES.YELLOW,
    IS_IN_RC: true,
  },
  DELIVERED_TO_RC: {
    KEY: 'DeliveredToReconditionCenter',
    LABEL: 'components.deliveredToRC',
    COLOR: STATUS_COLORS_NAMES.ORANGE,
    IS_IN_RC: true,
  },
  RECONDITIONING: {
    KEY: 'Reconditioning',
    LABEL: 'components.reconditioning',
    COLOR: STATUS_COLORS_NAMES.TURQUOISE,
    IS_IN_RC: true,
  },
  SHIPPING_BACK: {
    KEY: 'ShippedToCustomer',
    LABEL: 'components.shippingBack',
    COLOR: STATUS_COLORS_NAMES.BLUE,
    IS_IN_RC: true,
  },
  COMPLETED: {
    KEY: 'Completed',
    LABEL: 'components.completed',
    COLOR: STATUS_COLORS_NAMES.GRAY,
    IS_IN_RC: false,
  },
  DELIVERED_TO_CUSTOMER: {
    KEY: 'DeliveredToCustomer',
    LABEL: 'components.deliveredToCustomer',
    COLOR: STATUS_COLORS_NAMES.GREEN,
    IS_IN_RC: false,
  },
};

export const FALLBACK_STATUS = {
  KEY: 'Unknown',
  LABEL: 'components.unknown',
  COLOR: STATUS_COLORS_NAMES.LIGHT_GRAY,
};

export const BOX_DETAILS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    subtitle:
      'containers.thereWasAConnectionErrorWhileTryingToLoadYourProducts',
  },
  NO_ITEMS_FOUND: {
    subtitle: 'components.thereAreNoProductsInThisBox',
  },
  NO_SEARCH_RESULTS: {
    subtitle: 'components.thereAreNoProductsInThisBox',
  },
};

export const ORDER_DETAILS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    subtitle: 'containers.thereWasAConnectionErrorWhileTryingToLoadYourBoxes',
  },
  NO_ITEMS_FOUND: {
    subtitle: 'components.thereAreNoBoxesInThisOrder',
  },
  NO_SEARCH_RESULTS: {
    subtitle: 'components.thereAreNoBoxesInThisOrder',
  },
};

export const BOXES_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'containers.couldNotLoadBoxes',
    subtitle: 'containers.thereWasAConnectionErrorWhileTryingToLoadYourBoxes',
  },
  NO_ITEMS_FOUND: {
    title: 'containers.noBoxesAvailable',
    subtitle: 'containers.requestNewBoxOrCreateACustomOne',
  },
  NO_SEARCH_RESULTS: {
    title: 'containers.noBoxesFound',
    subtitle: 'containers.yourSearchQueryDidNotMatchAnyKnownBoxes',
  },
};

export const ORDERS_FEEDBACK_MESSAGES = {
  ERROR_LOADING_ITEMS: {
    title: 'error.errorLoadingOrders',
    subtitle: 'error.thereWasAnErrorWhileTryingToLoadOrders',
  },
  NO_ITEMS_FOUND: {
    title: 'error.noOrdersAvailable',
    subtitle: 'error.youHaventMadeAnyOrdersYet',
  },
  NO_SEARCH_RESULTS: {
    title: 'error.noOrdersFound',
    subtitle: 'error.yourFiltersDidNotMatchAnyKnownOrders',
  },
};

export const BOX_STATUSES = {
  ALL: 'All',
  ACTIVE_NOT_EMPTY: 'ActiveNotEmpty',
  ACTIVE_EMPTY: 'ActiveEmpty',
  ORDERED: 'Ordered',
  RECONDITIONING: 'Reconditioning',
};

export const BOXES_FILTER_OPTIONS = {
  READY_FOR_RESET: {
    label: 'components.readyForReset',
    key: 'ReadyForReset',
  },
  IN_ORDER: {
    label: 'components.inOrder',
    key: 'InOrder',
  },
  IN_USE: {
    label: 'components.inUse',
    key: 'InUse',
  },
  NOT_ACTIVATED: {
    label: 'components.notActivated',
    key: 'NotActivated',
  },
};
