import React from 'react';

import async from '../components/Async';
import { ReactComponent as ProductsIcon } from '../assets/icon/ic_menu_products_black.svg';
import { ReactComponent as GroupsIcon } from '../assets/icon/ic_menu_groups_black.svg';
import { ReactComponent as HistoryIcon } from '../assets/icon/ic_menu_history_black.svg';
import { ReactComponent as ReconditionIcon } from '../assets/icon/ic_recondition.svg';
import { ReactComponent as InspectionsIcon } from '../assets/icon/ic_inspections.svg';
import { ReactComponent as ShippedIcon } from '../assets/icon/ic_shipped.svg';
import { ReactComponent as OrdersIcon } from '../assets/icon/ic_incoming.svg';
import { ReactComponent as ArchiveIcon } from '../assets/icon/ic_clock_blue.svg';
import { ReactComponent as InboxIcon } from '../assets/icon/ic_inbox.svg';
import { ReactComponent as InspectionIcon } from '../assets/icon/ic_inspection.svg';
import { ReactComponent as ResponseIcon } from '../assets/icon/ic_response.svg';
import { ReactComponent as ArchiveLegalIcon } from '../assets/icon/ic_archive.svg';
import { PERMISSIONS, ROUTES, DEFAULT_ROUTE_TABS } from '../constants';
import { generateQueryString } from '../utils/tabs';
import { DATABASE_INTERNAL_PRODUCTS_QUERIES } from '../utils/database';
import { RECON_CENTER_SIDEBAR } from '../containers/ReconditioningCenterContainers/constants';
import { PRODUCT_AUTHENTICATION_SIDEBAR } from '../components/CounterfeitRiskComponents/LegalTeam/constants';

const History = async(() => import('../containers/ProductContainers/History'));
const Database = async(() =>
  import('../containers/DatabaseContainers/Database')
);
const Groups = async(() => import('../containers/GroupContainers/Groups'));

const ReconditioningDashboard = async(() =>
  import('../containers/ReconditioningContainers/ReconditioningDashboard')
);
const Incoming = async(() =>
  import('../containers/ReconditioningCenterContainers/Incoming/Incoming')
);
const Inspections = async(() =>
  import('../containers/ReconditioningCenterContainers/Inspections/Inspections')
);
const Reconditioning = async(() =>
  import(
    '../containers/ReconditioningCenterContainers/Reconditioning/Reconditioning'
  )
);
const Shipped = async(() =>
  import('../containers/ReconditioningCenterContainers/Shipped/Shipped')
);
const Archive = async(() =>
  import('../containers/ReconditioningCenterContainers/Archive/Archive')
);
const LegalInbox = async(() =>
  import('../containers/CounterfeitRiskContainers/LegalTeam/Inbox')
);
const LegalInspection = async(() =>
  import('../containers/CounterfeitRiskContainers/LegalTeam/Inspection')
);
const LegalInProgress = async(() =>
  import('../containers/CounterfeitRiskContainers/LegalTeam/InProgress')
);
const LegalResponse = async(() =>
  import('../containers/CounterfeitRiskContainers/LegalTeam/Response')
);
const LegalArchive = async(() =>
  import('../containers/CounterfeitRiskContainers/LegalTeam/Archive')
);

export const sidebarRoutes = [
  {
    path: ROUTES.DATABASE,
    search: DATABASE_INTERNAL_PRODUCTS_QUERIES,
    name: 'sharedComponents.toolsLibrary',
    iconActive: <ProductsIcon className='sidebar-icon icon-white' />,
    icon: <ProductsIcon className='sidebar-icon icon-tertiary' />,
    component: Database,
    permissions: [PERMISSIONS.VIEW_DATABASE_IN_SIDEBAR],
  },
  {
    path: RECON_CENTER_SIDEBAR.INCOMING.PATH,
    search: generateQueryString(RECON_CENTER_SIDEBAR.INCOMING.DEFAULT_TABS),
    name: RECON_CENTER_SIDEBAR.INCOMING.NAME,
    iconActive: <OrdersIcon className='sidebar-icon icon-white' />,
    icon: <OrdersIcon className='sidebar-icon icon-tertiary' />,
    component: Incoming,
    permissions: [RECON_CENTER_SIDEBAR.INCOMING.PERMISSION],
  },
  {
    path: RECON_CENTER_SIDEBAR.INSPECTION.PATH,
    search: generateQueryString(RECON_CENTER_SIDEBAR.INSPECTION.DEFAULT_TABS),
    name: RECON_CENTER_SIDEBAR.INSPECTION.NAME,
    iconActive: <InspectionsIcon className='sidebar-icon icon-white' />,
    icon: <InspectionsIcon className='sidebar-icon icon-tertiary' />,
    component: Inspections,
    permissions: [RECON_CENTER_SIDEBAR.INSPECTION.PERMISSION],
  },
  {
    path: RECON_CENTER_SIDEBAR.RECONDITIONING.PATH,
    search: generateQueryString(
      RECON_CENTER_SIDEBAR.RECONDITIONING.DEFAULT_TABS
    ),
    name: RECON_CENTER_SIDEBAR.RECONDITIONING.NAME,
    iconActive: <ReconditionIcon className='sidebar-icon icon-white' />,
    icon: <ReconditionIcon className='sidebar-icon icon-tertiary' />,
    component: Reconditioning,
    permissions: [RECON_CENTER_SIDEBAR.RECONDITIONING.PERMISSION],
  },
  {
    path: ROUTES.RECONDITIONING,
    search: generateQueryString(DEFAULT_ROUTE_TABS[ROUTES.RECONDITIONING]),
    name: 'modal.reconditioning',
    iconActive: <ReconditionIcon className='sidebar-icon icon-white' />,
    icon: <ReconditionIcon className='sidebar-icon icon-tertiary' />,
    component: ReconditioningDashboard,
    permissions: [
      PERMISSIONS.VIEW_RECONDITIONING_IN_SIDEBAR,
      PERMISSIONS.VIEW_RECONDITION,
    ],
    additionalPaths: [ROUTES.RECONDITIONING],
  },
  {
    path: RECON_CENTER_SIDEBAR.SHIPPED.PATH,
    search: generateQueryString(RECON_CENTER_SIDEBAR.SHIPPED.DEFAULT_TABS),
    name: RECON_CENTER_SIDEBAR.SHIPPED.NAME,
    iconActive: <ShippedIcon className='sidebar-icon icon-white' />,
    icon: <ShippedIcon className='sidebar-icon icon-tertiary' />,
    component: Shipped,
    permissions: [RECON_CENTER_SIDEBAR.SHIPPED.PERMISSION],
  },
  {
    path: RECON_CENTER_SIDEBAR.ARCHIVE.PATH,
    search: generateQueryString(RECON_CENTER_SIDEBAR.ARCHIVE.DEFAULT_TABS),
    name: RECON_CENTER_SIDEBAR.ARCHIVE.NAME,
    iconActive: <ArchiveIcon className='sidebar-icon icon-white' />,
    icon: <ArchiveIcon className='sidebar-icon icon-tertiary' />,
    component: Archive,
    permissions: [RECON_CENTER_SIDEBAR.ARCHIVE.PERMISSION],
  },
  {
    path: ROUTES.GROUPS,
    name: 'components.groups',
    iconActive: <GroupsIcon className='sidebar-icon icon-white' />,
    icon: <GroupsIcon className='sidebar-icon icon-tertiary' />,
    iconSize: 45,
    component: Groups,
    additionalPaths: [`${ROUTES.ORGANIZATION}`],
    permissions: [PERMISSIONS.VIEW_GROUPS_IN_SIDEBAR],
  },
  {
    path: ROUTES.HISTORY,
    name: 'components.history',
    iconActive: <HistoryIcon className='sidebar-icon icon-white' />,
    icon: <HistoryIcon className='sidebar-icon icon-tertiary' />,
    component: History,
    permissions: [
      PERMISSIONS.VIEW_HISTORY_IN_SIDEBAR,
      PERMISSIONS.VIEW_HISTORY,
    ],
  },
  {
    path: PRODUCT_AUTHENTICATION_SIDEBAR.INBOX.PATH,
    name: PRODUCT_AUTHENTICATION_SIDEBAR.INBOX.NAME,
    iconActive: <InboxIcon className='sidebar-icon icon-white' />,
    icon: <InboxIcon className='sidebar-icon icon-tertiary' />,
    component: LegalInbox,
    permissions: [PRODUCT_AUTHENTICATION_SIDEBAR.INBOX.PERMISSION],
  },
  {
    path: PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION.PATH,
    name: PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION.NAME,
    iconActive: <InspectionIcon className='sidebar-icon icon-white' />,
    icon: <InspectionIcon className='sidebar-icon icon-tertiary' />,
    component: LegalInspection,
    permissions: [PRODUCT_AUTHENTICATION_SIDEBAR.INSPECTION.PERMISSION],
  },
  {
    path: PRODUCT_AUTHENTICATION_SIDEBAR.IN_PROGRESS.PATH,
    name: PRODUCT_AUTHENTICATION_SIDEBAR.IN_PROGRESS.NAME,
    iconActive: <InspectionIcon className='sidebar-icon icon-white' />,
    icon: <InspectionIcon className='sidebar-icon icon-tertiary' />,
    component: LegalInProgress,
    permissions: [PRODUCT_AUTHENTICATION_SIDEBAR.IN_PROGRESS.PERMISSION],
  },
  {
    path: PRODUCT_AUTHENTICATION_SIDEBAR.RESPONSE.PATH,
    name: PRODUCT_AUTHENTICATION_SIDEBAR.RESPONSE.NAME,
    iconActive: <ResponseIcon className='sidebar-icon icon-white' />,
    icon: <ResponseIcon className='sidebar-icon icon-tertiary' />,
    component: LegalResponse,
    permissions: [PRODUCT_AUTHENTICATION_SIDEBAR.RESPONSE.PERMISSION],
  },
  {
    path: PRODUCT_AUTHENTICATION_SIDEBAR.ARCHIVE.PATH,
    name: PRODUCT_AUTHENTICATION_SIDEBAR.ARCHIVE.NAME,
    iconActive: <ArchiveLegalIcon className='sidebar-icon icon-white' />,
    icon: <ArchiveLegalIcon className='sidebar-icon icon-tertiary' />,
    component: LegalArchive,
    permissions: [PRODUCT_AUTHENTICATION_SIDEBAR.ARCHIVE.PERMISSION],
  },
];
