import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TriangleBadge from './TriangleBadge';
import PillButton from './PillButton';
import {
  displayBoxWeight,
  getColorOfBoxFlag,
  getProviderLogo,
  checkIsBoxInOrder,
  getGridContainerSizes,
} from '../../utils/box';
import { CUSTOM_BOX_PROVIDER, THEME_COLORS_NAMES } from '../../constants';
import { removeHighlightedIds } from '../../redux/actions/boxActions';
import CustomCol from './CustomCol';
import BoxProvider from '../ReconditioningComponents/BoxProvider';
import BoxInfoCard from '../ReconditioningComponents/BoxInfoCard';
import { BOX_CARD_SIZES } from './constants';
import useHighlight from '../../hooks/Shared/useHighlight';
import { findStatusDetails } from '../../utils/orders';
import { CUSTOMER_ORDER_STATUSES } from '../../containers/ReconditioningContainers/constants';
import TwoSideCard from './TwoSideCard';

const BoxCard = ({
  box,
  buttonLabel = 'components.addToBox',
  buttonColor = THEME_COLORS_NAMES.SECONDARY,
  handleButtonClick = () => {},
  buttonDisabled = false,
  withoutButton = false,
  containerClassName = '',
  className = '',
  buttonClassName = '',
  highlightedBoxesIds,
  isSidebarOpened,
  removeHighlightedIds,
  onClick,
  size = BOX_CARD_SIZES.WINDOW_SIZE,
  isCustomBoxOrRequestBoxModalOpened,
}) => {
  const { t } = useTranslation();
  const { handleAnimationStart, handleAnimationEnd } = useHighlight(
    box.id,
    removeHighlightedIds
  );
  const [showDescription, setShowDescription] = useState(false);

  const providerLogo = getProviderLogo(box.boxProvider);
  const isBoxInOrder = checkIsBoxInOrder(box);

  // if box card has info/description visible - return it to initial state before adding new box
  useEffect(() => {
    if (isCustomBoxOrRequestBoxModalOpened && showDescription) {
      setShowDescription(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomBoxOrRequestBoxModalOpened]);

  const renderButton = () =>
    withoutButton ? (
      <div className='h-1 invisible' /> // placeholder to center the data
    ) : (
      <PillButton
        id={`box-button-${box.id}`}
        color={buttonColor}
        disabled={buttonDisabled}
        onClick={handleButtonClick}
        className={`${
          size === BOX_CARD_SIZES.WINDOW_SIZE ? 'w-50' : 'tab-size-button'
        } px-0 ${buttonClassName}`}
      >
        {t(buttonLabel)}
      </PillButton>
    );

  return (
    <CustomCol
      {...getGridContainerSizes(size, isSidebarOpened)}
      className={`${containerClassName}`}
      key={box.id}
    >
      <TriangleBadge
        color={getColorOfBoxFlag(box)}
        id={`triangle-badge-${box.id}`}
      >
        <TwoSideCard
          containerClassName={`box-card ${className}`}
          backSideContainerClassName={
            providerLogo || box.boxProvider === CUSTOM_BOX_PROVIDER
              ? 'justify-content-between'
              : 'justify-content-center'
          }
          frontSideContainerClassName='justify-content-between'
          header={<BoxProvider box={box} imageHeight={28} imageWidth={74} />}
          backSide={<BoxInfoCard box={box} />}
          backSideFooter={<div className='h-1 invisible' />} // placeholder to center the data
          frontSide={
            <>
              <div
                className={cs(
                  'd-flex flex-column text-center align-items-center justify-content-center mb-1 w-100',
                  { 'pt-2': !providerLogo }
                )}
              >
                {isBoxInOrder ? (
                  <>
                    <span className='font-size-sm text-uppercase'>
                      {t(
                        findStatusDetails(
                          CUSTOMER_ORDER_STATUSES,
                          box.customerOrderStatus
                        ).LABEL
                      )}
                    </span>
                    <span className='text-break font-weight-bold'>
                      {box.orderNumber}
                    </span>
                  </>
                ) : (
                  <>
                    <span className='font-size-sm text-truncate w-100 px-3'>
                      {box.name?.toUpperCase()}
                    </span>
                    <span className='text-break font-weight-bold'>
                      {displayBoxWeight(box)}
                    </span>
                  </>
                )}
              </div>
              {renderButton()}
            </>
          }
          id={box.id}
          onClick={onClick}
          isHighlighted={highlightedBoxesIds?.includes(box.id)}
          handleAnimationStart={() => handleAnimationStart(box.id)}
          handleAnimationEnd={() => handleAnimationEnd(box.id)}
        />
      </TriangleBadge>
    </CustomCol>
  );
};

BoxCard.propTypes = {
  box: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  withoutButton: PropTypes.bool,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  handleButtonClick: PropTypes.func,
  highlightedBoxesIds: PropTypes.array,
  isSidebarOpened: PropTypes.bool,
  removeHighlightedIds: PropTypes.func.isRequired,
  size: PropTypes.string,
  isCustomBoxOrRequestBoxModalOpened: PropTypes.bool.isRequired,
};

export default connect(
  ({ box, sidebar }) => ({
    highlightedBoxesIds: box.highlightedIds,
    isSidebarOpened: sidebar.isOpen,
  }),
  { removeHighlightedIds }
)(BoxCard);
