import * as types from '../constants';
import initialState from '../initialState';
import { extractExtension } from '../../utils/file';

export default function reducer(state = initialState.assembly, action) {
  const { type, payload } = action;

  switch (type) {
    case types.GET_ASSEMBLIES_SUCCESS:
      return {
        ...state,
        assemblies:
          payload.page === 1
            ? [...payload.assemblies]
            : [...state.assemblies, ...payload.assemblies],
      };
    case types.SET_ASSEMBLIES_SUCCESS:
      return {
        ...state,
        assemblies: payload.assemblies,
        highlightedIds: payload.assemblies?.length ? state.highlightedIds : [],
      };
    case types.SET_ASSEMBLIES_TO_ADD_TO_START_SUCCESS:
      return {
        ...state,
        assembliesToAddToStart: [...payload.assembliesToAddToStart],
      };
    case types.SET_ASSEMBLY_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS:
      return {
        ...state,
        assemblyIdsToFilterOutWhileFetching: [...payload.ids],
      };
    case types.ADD_ASSEMBLIES_TO_START_SUCCESS:
      const idsOfAssembliesToAdd = state.assembliesToAddToStart.map(
        ({ id }) => id
      );
      const filteredAlreadyInStateAssemblies = state.assemblies.filter(
        ({ id }) => !idsOfAssembliesToAdd.includes(id)
      );
      return {
        ...state,
        assemblies: [
          ...state.assembliesToAddToStart,
          ...filteredAlreadyInStateAssemblies,
        ],
        assemblyIdsToFilterOutWhileFetching: [
          ...state.assemblyIdsToFilterOutWhileFetching,
          ...idsOfAssembliesToAdd,
        ],
        assembliesToAddToStart: [],
      };
    case types.GET_ASSEMBLY_SUCCESS:
      return {
        ...state,
        details: payload.details,
      };
    case types.GET_ASSEMBLY_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: payload.details,
      };
    case types.GET_ASSEMBLY_INSTANCE_LOCATIONS_SUCCESS:
      return {
        ...state,
        instanceLocations: payload.instanceLocations,
      };
    case types.CREATE_ASSEMBLY_INSTANCE_SUCCESS:
      return {
        ...state,
        assemblies: [
          {
            ...state.assemblies.find(({ id }) => id === payload.id),
            assemblyInstanceCount:
              state.assemblies.find(({ id }) => id === payload.id)
                .assemblyInstanceCount + 1,
          },
          ...state.assemblies.filter(({ id }) => id !== payload.id),
        ],
      };
    case types.CREATE_ASSEMBLY_SUCCESS:
      return {
        ...state,
        assemblies: [
          payload.assembly,
          ...state.assemblies.filter(({ id }) => id !== payload.assembly.id),
        ],
      };
    case types.HIGHLIGHT_ASSEMBLY_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...payload.data.details,
          products: payload.data.details?.highlightedProductsIds
            ? state.details.products.map((product) => ({
                ...product,
                isHighlighted:
                  !!payload.data.details.highlightedProductsIds.includes(
                    product.id
                  ),
              }))
            : state.details.products,
        },
        assemblies: payload.data.highlightedAssembliesIds
          ? state.assemblies.map((assembly) => {
              const isAssemblyHighlighted =
                !!payload.data.highlightedAssembliesIds.includes(assembly.id);
              return {
                ...assembly,
                isHighlighted:
                  isAssemblyHighlighted && payload.data.isHighlighted,
                isFooterHighlighted:
                  isAssemblyHighlighted && payload.data.isFooterHighlighted,
              };
            })
          : state.assemblies,
        instance: {
          ...state.instance,
          ...payload.data.instance,
          products: payload.data.instance?.highlightedProductsIds
            ? state.instance.products.map((product) => ({
                ...product,
                isHighlighted:
                  !!payload.data.instance.highlightedProductsIds.includes(
                    product.id
                  ),
              }))
            : state.instance.products,
        },
        highlightedIds: payload.data.highlightedAssembliesIds,
      };
    case types.DELETE_ASSEMBLY_SUCCESS:
      return {
        ...state,
        assemblies: state.assemblies.filter(({ id }) => id !== payload.id),
      };
    case types.DELETE_ASSEMBLY_OF_GROUP_SUCCESS:
      return {
        ...state,
        assemblies: state.assemblies.filter(
          ({ groupId }) => groupId !== payload.groupId
        ),
      };
    case types.EDIT_ASSEMBLY_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...payload.data,
        },
        instance: {
          ...state.instance,
          assemblyName: payload.data.name,
          assemblyDescription: payload.data.description,
          assemblyGroupId: payload.data.groupId,
          assemblyGroupName: payload.data.groupName,
          imageUrl: payload.data?.imageUrl,
          products: payload.data.products.map((product) => {
            const instanceProduct = state.instance.products?.find(
              ({ id }) => id === product.id
            );
            return {
              ...(instanceProduct || { ...product, productInstanceCount: 0 }),
              count: product.count,
            };
          }),
        },
      };
    case types.CLEAR_ASSEMBLY_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: {},
      };
    case types.EDIT_ASSEMBLY_INSTANCE_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          ...payload.data,
        },
      };
    case types.HIGHLIGHT_ASSEMBLY_INSTANCE_EMPTY_TAG_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          nicknameHighlighted: false,
          descriptionHighlighted: false,
          emptyTagHighlighted: true,
          ...(payload.unhighlightAllProducts
            ? {
                products: state.instance.products?.map((prod) => ({
                  ...prod,
                  isHighlighted: false,
                })),
              }
            : {}),
        },
      };
    case types.HIGHLIGHT_ASSEMBLY_INSTANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          nicknameHighlighted: false,
          descriptionHighlighted: false,
          products: state.instance.products?.map((prod) =>
            payload.products.includes(prod.id)
              ? { ...prod, isHighlighted: true }
              : { ...prod, isHighlighted: false }
          ),
          ...(payload.unhighlightEmptyTag
            ? {
                emptyTagHighlighted: false,
              }
            : {}),
        },
      };
    case types.GET_ASSEMBLY_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS:
      return {
        ...state,
        instanceTimeTrackerHistory: {
          ...state.instanceTimeTrackerHistory,
          data: payload.instanceTimeTrackerHistory,
        },
      };
    case types.SET_ASSEMBLY_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID:
      return {
        ...state,
        instanceTimeTrackerHistory: {
          ...state.instanceTimeTrackerHistory,
          highlightedId: payload.highlightedId,
        },
      };
    case types.SET_ASSEMBLY_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS:
      return {
        ...state,
        instanceTimeTrackerHistory: {
          ...state.instanceTimeTrackerHistory,
          data: payload.instanceTimeTrackerHistory,
          highlightedId: payload.instanceTimeTrackerHistory[0].id,
        },
        instance: {
          ...state.instance,
          [payload.propertyName]: {
            id: payload.propertyId,
            value: payload.propertyValue,
          },
          totalTime: payload.totalTimeNewValue,
        },
      };
    case types.GET_ASSEMBLY_INSTANCE_CONDITION_RATINGS_SUCCESS:
      return {
        ...state,
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          data: payload.instanceConditionRatings,
        },
      };
    case types.SET_ASSEMBLY_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID:
      return {
        ...state,
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          highlightedId: payload.highlightedId,
        },
      };
    case types.ADD_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          conditionRating: { id: payload.id, value: payload.value },
        },
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          data: payload.conditionRatings,
        },
      };
    case types.DELETE_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS:
      return {
        ...state,
        instanceConditionRatings: {
          ...state.instanceConditionRatings,
          data: payload.conditionRatings,
        },
        instance: {
          ...state.instance,
          conditionRating: payload.newInstanceConditionRating,
        },
      };
    case types.GET_ASSEMBLY_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS:
      return {
        ...state,
        instance: {
          ...state.instance,
          ...payload.instanceProperties,
        },
      };
    case types.HIGHLIGHT_ASSEMBLY_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: state.threads.general.map((thread) => {
            if (payload.id === thread.id) {
              return {
                ...thread,
                isHighlighted: true,
              };
            }
            return { ...thread, isHighlighted: false };
          }),
          instance: state.threads.instance.map((thread) => {
            if (payload.id === thread.id) {
              return {
                ...thread,
                isHighlighted: true,
              };
            }
            return { ...thread, isHighlighted: false };
          }),
        },
      };
    case types.GET_ASSEMBLY_THREADS_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: payload.threads,
        },
      };
    case types.GET_ASSEMBLY_INSTANCE_THREADS_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: payload.threads,
        },
      };
    case types.ADD_ASSEMBLY_GENERAL_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: payload.threads,
        },
      };
    case types.ADD_ASSEMBLY_INSTANCE_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: payload.threads,
        },
      };
    case types.EDIT_ASSEMBLY_GENERAL_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: [
            ...state.threads.general.map((thread) =>
              thread.id === payload.id
                ? {
                    ...thread,
                    ...payload.thread,
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.EDIT_ASSEMBLY_INSTANCE_THREAD_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: [
            ...state.threads.instance.map((thread) =>
              thread.id === payload.id
                ? {
                    ...thread,
                    ...payload.thread,
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.RENAME_ASSEMBLY_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: [
            ...state.threads.general.map((thread) =>
              thread.id === payload.threadId
                ? {
                    ...thread,
                    files: thread.files.map((file) =>
                      file.id === payload.fileId
                        ? {
                            ...file,
                            name: `${payload.filename}${extractExtension(
                              file.name
                            )}`,
                          }
                        : file
                    ),
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.RENAME_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: [
            ...state.threads.instance.map((thread) =>
              thread.id === payload.threadId
                ? {
                    ...thread,
                    files: thread.files.map((file) =>
                      file.id === payload.fileId
                        ? {
                            ...file,
                            name: `${payload.filename}${extractExtension(
                              file.name
                            )}`,
                          }
                        : file
                    ),
                    isHighlighted: false,
                    updatedAt: Date.now(),
                  }
                : thread
            ),
          ],
        },
      };
    case types.DELETE_ASSEMBLY_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: state.threads.general.map((thread) =>
            thread.id === payload.threadId
              ? {
                  ...thread,
                  files: thread.files.filter(({ id }) => id !== payload.fileId),
                }
              : thread
          ),
        },
      };
    case types.DELETE_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: state.threads.instance.map((thread) =>
            thread.id === payload.threadId
              ? {
                  ...thread,
                  files: thread.files.filter(({ id }) => id !== payload.fileId),
                }
              : thread
          ),
        },
      };
    case types.DELETE_ASSEMBLY_GENERAL_THREAD_SUCCESS:
    case types.MARK_ASSEMBLY_GENERAL_THREAD_AS_DONE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          general: state.threads.general
            .filter(({ id }) => id !== payload.id)
            .map((thread) => ({
              ...thread,
              isHighlighted: false,
            })),
          instance: state.threads.instance.map((thread) => ({
            ...thread,
            isHighlighted: false,
          })),
        },
      };
    case types.DELETE_ASSEMBLY_INSTANCE_THREAD_SUCCESS:
    case types.MARK_ASSEMBLY_INSTANCE_THREAD_AS_DONE_SUCCESS:
      return {
        ...state,
        threads: {
          ...state.threads,
          instance: state.threads.instance
            .filter(({ id }) => id !== payload.id)
            .map((thread) => ({
              ...thread,
              isHighlighted: false,
            })),
          general: state.threads.general.map((thread) => ({
            ...thread,
            isHighlighted: false,
          })),
        },
      };
    default:
      return state;
  }
}
