import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import { ReactComponent as DeleteTrashIcon } from '../../assets/icon/icons-ic-trash-red.svg';
import Image from './Image';
import {
  DEFAULT_IMAGE_FALLBACK,
  MAX_NUMBER_OF_CHARACTERS,
} from '../../constants';
import { calculateMaxNumberValueByNumberOfAllowedDigits } from '../../utils/shared';
import Counter from './Counter';

const ProductRow = ({
  className = '',
  image = DEFAULT_IMAGE_FALLBACK,
  name,
  code,
  handleDeleteRow,
  isCountable,
  counterValue,
  setProductCountValue,
  minCounterValue,
  maxCounterValue = calculateMaxNumberValueByNumberOfAllowedDigits(
    MAX_NUMBER_OF_CHARACTERS.PRODUCT_QUANTITY
  ),
}) => {
  return (
    <Row
      className={`product-row-container py-2 px-4 border-bottom ${className}`}
    >
      <Col sm={1} className='p-0'>
        <Image
          src={image}
          width={60}
          height={60}
          className='image-object-fit-cover border'
        />
      </Col>
      <Col sm={4} className='font-weight-bold text-overflow-wrap'>
        {name}
      </Col>
      <Col sm={3} className='text-overflow-wrap'>
        {code}
      </Col>
      {!!(isCountable || handleDeleteRow) && (
        <Col className='d-flex align-items-center justify-content-end'>
          {isCountable && (
            <Counter
              maxCounterValue={maxCounterValue}
              minCounterValue={minCounterValue}
              counterValue={counterValue}
              handleCounterValueChange={setProductCountValue}
            />
          )}
          {handleDeleteRow && (
            <div>
              <DeleteTrashIcon
                width={24}
                height={24}
                onClick={handleDeleteRow}
                className='icon-danger hoverable'
              />
            </div>
          )}
        </Col>
      )}
    </Row>
  );
};

ProductRow.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  handleDeleteRow: PropTypes.func,
  setProductCountValue: PropTypes.func,
  isCountable: PropTypes.bool,
  counterValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minCounterValue: PropTypes.number,
  maxCounterValue: PropTypes.number,
};

export default ProductRow;
