import React, { useState } from 'react';

import {
  Dropdown as ReactstrapDropdown,
  DropdownItem as ReactstrapDropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';

import DropdownItem from './DropdownItem';
import { COLORS } from '../../constants/theme';
import CustomCheckbox from './CustomCheckbox';

const SelectAllDropdown = ({
  items,
  selectedItems,
  setSelectedItems,
  className = '',
  toggle,
  withHighZIndex = true,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCheckboxClick = (id, isChecked) => {
    if (isChecked) {
      setSelectedItems([
        ...selectedItems,
        items.find((item) => id === item.id),
      ]);
    } else {
      setSelectedItems(selectedItems.filter((item) => id !== item.id));
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length !== items.length) {
      setSelectedItems(items);
    } else {
      setSelectedItems([]);
    }
  };

  return (
    <ReactstrapDropdown
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen(!dropdownOpen)}
      className={`custom-dropdown-height  ${
        withHighZIndex ? 'custom-dropdown-z-index' : ''
      }`}
    >
      <DropdownToggle
        tag='div'
        data-toggle='dropdown'
        aria-expanded={dropdownOpen}
        disabled={disabled}
        className={cs(
          `custom-dropdown-toggle header-dropdown-toggle justify-content-between h-100 d-flex align-items-center pr-2 pl-2 ${className}`,
          {
            'bg-secondary text-white': dropdownOpen,
            'bg-white text-txt-primary custom-dropdown-border-deactivated':
              !dropdownOpen && !disabled,
            'custom-dropdown-border-active':
              selectedItems.length || dropdownOpen,
          }
        )}
      >
        {toggle}
        <ChevronDown
          size={20}
          color={
            dropdownOpen
              ? COLORS.WHITE
              : disabled
              ? COLORS.BUTTON_DISABLED
              : COLORS.TERTIARY
          }
        />
      </DropdownToggle>
      <DropdownMenu className='custom-dropdown border-radius-xl mt-1' right>
        <DropdownItem
          label={t('components.selectAll')}
          toggle={false}
          handleClick={handleSelectAll}
          suffix={
            <CustomCheckbox
              isChecked={selectedItems.length === items.length}
              handleChange={handleSelectAll}
              id='select-all'
            />
          }
          className='font-weight-bold text-txt-primary border-0 mt-1'
        />
        <ReactstrapDropdownItem
          className='mx-auto border-bottom-bd-color'
          divider
        />
        {items &&
          items.map(({ id, name }) => {
            const checked = !!selectedItems.find((item) => item.id === id);
            return (
              <DropdownItem
                key={id}
                id={id}
                label={name}
                toggle={false}
                handleClick={() => handleCheckboxClick(id, !checked)}
                suffix={
                  <CustomCheckbox
                    isChecked={checked}
                    handleChange={() => handleCheckboxClick(id, !checked)}
                    id={`item-${id}`}
                  />
                }
              />
            );
          })}
      </DropdownMenu>
    </ReactstrapDropdown>
  );
};

SelectAllDropdown.propTypes = {
  toggle: PropTypes.any,
  disabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  className: PropTypes.string,
  withHighZIndex: PropTypes.bool,
};

export default SelectAllDropdown;
