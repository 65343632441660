import { READER_STATES_IGNORING_FOCUSED_ELEMENTS } from '../../constants';
import * as types from '../constants';
import initialState from '../initialState';

export default function reducer(state = initialState.reader, action) {
  const { type, payload } = action;

  switch (type) {
    case types.CHANGE_READER_STATE:
      return {
        ...state,
        state: payload.state,
        ignoreFocusedElement: READER_STATES_IGNORING_FOCUSED_ELEMENTS.includes(
          payload.state
        ),
      };
    case types.SCAN_TAG_SUCCESS:
      return {
        ...state,
        scannedTag: payload.tag,
        codeType: payload.type || null,
      };
    case types.SET_SCANNING_SUCCESS:
      return {
        ...state,
        isScanning: payload.isScanning,
      };
    default:
      return state;
  }
}
