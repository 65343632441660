import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { ReactComponent as UnknownErrorIcon } from '../../../assets/icon/img_unknown_error.svg';
import ModalStep from './ModalStep';
import { BRIDGE_SUPPORT_EMAIL } from '../../../containers/DatabaseContainers/constants';

const UnknownError = ({ handleClose, handleTryAgain }) => {
  const { t } = useTranslation();

  return (
    <ModalStep
      title={t('error.oops')}
      subtitle={
        <Trans
          i18nKey='error.looksLikeSomethingHappenedPleaseTryAgain'
          components={[<br />, <span className='font-weight-bold' />]}
        />
      }
      handleCancel={handleClose}
      cancelButtonText={t('button.leave')}
      handleNext={handleTryAgain}
      nextButtonText={t('button.tryAgain')}
      isCentered
    >
      <UnknownErrorIcon width={345} height={345} />
      <Trans
        i18nKey='error.ifTheProblemPersistsPleaseContactSupport'
        components={[
          <span
            className='cursor-pointer error-link mb-0 flex-nowrap'
            onClick={() =>
              window.open(`mailto:${BRIDGE_SUPPORT_EMAIL}`, '_blank')
            }
          />,
        ]}
      />
    </ModalStep>
  );
};

UnknownError.propTypes = {
  handleClose: PropTypes.func,
  handleTryAgain: PropTypes.func,
};

export default UnknownError;
