import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { errorIcon } from '../../../assets/icon';
import NewModalStep from './NewModalStep';
import NewDone from './NewDone';

const ErrorStep = ({
  title,
  subtitle = '',
  image = errorIcon,
  buttonContent,
  handleButton,
  handleClose,
  leftButtonContent = '',
  handleClickLeftButton = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <NewModalStep
      handleClickRightButton={handleButton || handleClose}
      rightButtonContent={buttonContent || t('button.cancel')}
      handleCancel={handleClose}
      leftButtonContent={leftButtonContent}
      handleClickLeftButton={handleClickLeftButton}
    >
      <NewDone
        title={title || t('error.somethingWentWrong')}
        subtitle={subtitle}
        image={image}
      />
    </NewModalStep>
  );
};

ErrorStep.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  buttonContent: PropTypes.string,
  handleButton: PropTypes.func,
  handleClickLeftButton: PropTypes.func,
  leftButtonContent: PropTypes.string,
};

export default ErrorStep;
