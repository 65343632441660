import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  displayArticleNumber,
  displayBoxVendors,
  displayTypesOfToolsBoxAccepts,
} from '../../utils/box';

const ActiveBoxInfoCard = ({ box }) => {
  const { t } = useTranslation();

  const showData = (title, data) => (
    <div className='text-center'>
      <span className='font-weight-bold'>{`${t(title)}: `}</span>
      <span className='text-break-all'>{`${data || t('modal.noData')}`}</span>
    </div>
  );

  return (
    <div className='d-flex flex-column align-items-center font-size-sm my-1'>
      {showData('components.articleNumber', displayArticleNumber(box))}
      <div className='line bottom-line border-bd-color my-1' />
      {showData('components.accepts', displayTypesOfToolsBoxAccepts(box))}
      <div className='line bottom-line border-bd-color my-1' />
      {showData('components.toolVendor', displayBoxVendors(box))}
    </div>
  );
};

ActiveBoxInfoCard.propTypes = {
  box: PropTypes.object.isRequired,
};

export default ActiveBoxInfoCard;
