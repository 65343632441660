// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE';

// Auth
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

// Group
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  'GET_ORGANIZATION_DETAILS_SUCCESS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const HIGHLIGHT_GROUP_DETAILS_SUCCESS =
  'HIGHLIGHT_GROUP_DETAILS_SUCCESS';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_ORGANIZATION_SUCCESS = 'EDIT_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_MEMBERS = 'GET_ORGANIZATION_MEMBERS';
export const GET_ORGANIZATION_ADMINS = 'GET_ORGANIZATION_ADMINS';
export const DELETE_ORGANIZATION_MEMBER_SUCCESS =
  'DELETE_ORGANIZATION_MEMBER_SUCCESS';
export const DELETE_GROUP_MEMBER_SUCCESS = 'DELETE_GROUP_MEMBER_SUCCESS';
export const DELETE_PRODUCTS_OF_GROUP_SUCCESS =
  'DELETE_PRODUCTS_OF_GROUP_SUCCESS';
export const REVOKE_GROUP_ADMIN_RIGHTS = 'REVOKE_GROUP_ADMIN_RIGHTS';
export const REVOKE_ORGANIZATION_ADMIN_RIGHTS =
  'REVOKE_ORGANIZATION_ADMIN_RIGHTS';
export const ASSIGN_GROUP_ADMIN_RIGHTS = 'ASSIGN_GROUP_ADMIN_RIGHTS';
export const ASSIGN_ORGANIZATION_ADMIN_RIGHTS =
  'ASSIGN_ORGANIZATION_ADMIN_RIGHTS';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const GET_CRIBWISE_STATUS_CONNECTION_SUCCESS =
  'GET_CRIBWISE_STATUS_CONNECTION_SUCCESS';
export const UPDATE_CRIBWISE_STATUS_CONNECTION_SUCCESS =
  'UPDATE_CRIBWISE_STATUS_CONNECTION_SUCCESS';

// Reader
export const CHANGE_READER_STATE = 'CHANGE_READER_STATE';
export const SCAN_TAG_SUCCESS = 'SCAN_TAG_SUCCESS';
export const SET_SCANNING_SUCCESS = 'SET_SCANNING_SUCCESS';
export const SET_FETCHING_SUCCESS = 'SET_FETCHING_SUCCESS';

// Product
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const SET_PRODUCTS_SUCCESS = 'SET_PRODUCTS_SUCCESS';
export const ADD_PRODUCTS_TO_START_SUCCESS = 'ADD_PRODUCTS_TO_START_SUCCESS';
export const SET_PRODUCTS_TO_ADD_TO_START_SUCCESS =
  'SET_PRODUCTS_TO_ADD_TO_START_SUCCESS';
export const SET_PRODUCT_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS =
  'SET_PRODUCT_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS';
export const GET_PRODUCT_INSTANCE_SUCCESS = 'GET_PRODUCT_INSTANCE_SUCCESS';
export const GET_PRODUCT_INSTANCE_CONDITION_RATINGS_SUCCESS =
  'GET_PRODUCT_INSTANCE_CONDITION_RATINGS_SUCCESS';
export const SET_PRODUCT_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID =
  'SET_PRODUCT_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID';
export const DELETE_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS =
  'DELETE_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS';
export const ADD_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS =
  'ADD_PRODUCT_INSTANCE_CONDITION_RATING_SUCCESS';
export const GET_PRODUCT_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS =
  'GET_PRODUCT_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS';
export const SET_PRODUCT_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID =
  'SET_PRODUCT_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID';
export const SET_PRODUCT_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS =
  'SET_PRODUCT_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS';
export const GET_PRODUCT_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS =
  'GET_PRODUCT_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS';
export const SYNC_PRODUCT_SUCCESS = 'SYNC_PRODUCT_SUCCESS';
export const HIGHLIGHT_PRODUCT_DETAILS_SUCCESS =
  'HIGHLIGHT_PRODUCT_DETAILS_SUCCESS';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const IMPORT_PRODUCTS_SUCCESS = 'IMPORT_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_EXTERNAL_SUCCESS = 'GET_PRODUCTS_EXTERNAL_SUCCESS';
export const CLEAR_EXTERNAL_PRODUCTS_SUCCESS =
  'CLEAR_EXTERNAL_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_SECO_TOOLS_SUCCESS =
  'GET_PRODUCTS_SECO_TOOLS_SUCCESS';
export const GET_PRODUCTS_UNITED_TOOLS_SUCCESS =
  'GET_PRODUCTS_UNITED_TOOLS_SUCCESS';
export const GET_PRODUCTS_WALTER_SUCCESS = 'GET_PRODUCTS_WALTER_SUCCESS';
export const GET_PRODUCTS_COROMANT_SUCCESS = 'GET_PRODUCTS_COROMANT_SUCCESS';
export const GET_PRODUCTS_DORMER_PRAMET_SUCCESS =
  'GET_PRODUCTS_DORMER_PRAMET_SUCCESS';
export const EDIT_PRODUCT_TEMPLATE_DETAILS_SUCCESS =
  'EDIT_PRODUCT_TEMPLATE_DETAILS_SUCCESS';
export const EDIT_PRODUCT_INSTANCE_DETAILS = 'EDIT_PRODUCT_INSTANCE_DETAILS';
export const HIGHLIGHT_PRODUCT_SUCCESS = 'HIGHLIGHT_PRODUCT_SUCCESS';
export const GET_PRODUCT_INSTANCES_SUCCESS = 'GET_PRODUCT_INSTANCES_SUCCESS';
export const GET_PRODUCT_INSTANCE_LOCATIONS_SUCCESS =
  'PRODUCT_INSTANCE_LOCATIONS';
export const GET_PRODUCT_SUPPORT_MESSAGES_SUCCESS =
  'GET_PRODUCT_SUPPORT_MESSAGES_SUCCESS';
export const UPDATE_PRODUCT_SUPPORT_MESSAGE_SUCCESS =
  'UPDATE_PRODUCT_SUPPORT_MESSAGE_SUCCESS';
export const ADD_PRODUCT_SUPPORT_MESSAGE_SUCCESS =
  'ADD_PRODUCT_SUPPORT_MESSAGE_SUCCESS';
export const SET_PRODUCTS_SEARCH_SUCCESS = 'SET_PRODUCTS_SEARCH_SUCCESS';
export const SET_GROUPS_SEARCH_SUCCESS = 'SET_GROUPS_SEARCH_SUCCESS';
export const DESELECT_GROUP_SUCCESS = 'DESELECT_GROUP_SUCCESS';
export const GET_PRODUCT_ASSEMBLIES_SUCCESS = 'GET_PRODUCT_ASSEMBLIES_SUCCESS';
export const GET_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS =
  'GET_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS';
export const GET_STOCK_LEVEL_SUCCESS = 'GET_STOCK_LEVEL_SUCCESS';
export const CLEAR_PRODUCT_ASSEMBLIES_SUCCESS =
  'CLEAR_PRODUCT_ASSEMBLIES_SUCCESS';
export const CLEAR_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS =
  'CLEAR_PRODUCT_INSTANCE_ASSEMBLY_INSTANCE_SUCCESS';
export const HIGHLIGHT_PRODUCT_THREAD_SUCCESS =
  'HIGHLIGHT_PRODUCT_THREAD_SUCCESS';
export const GET_PRODUCT_THREADS_SUCCESS = 'GET_PRODUCT_THREADS_SUCCESS';
export const GET_PRODUCT_INSTANCE_THREADS_SUCCESS =
  'GET_PRODUCT_INSTANCE_THREADS_SUCCESS';
export const ADD_PRODUCT_GENERAL_THREAD_SUCCESS =
  'ADD_PRODUCT_GENERAL_THREAD_SUCCESS';
export const ADD_PRODUCT_INSTANCE_THREAD_SUCCESS =
  'ADD_PRODUCT_INSTANCE_THREAD_SUCCESS';
export const EDIT_PRODUCT_GENERAL_THREAD_SUCCESS =
  'EDIT_PRODUCT_GENERAL_THREAD_SUCCESS';
export const EDIT_PRODUCT_INSTANCE_THREAD_SUCCESS =
  'EDIT_PRODUCT_INSTANCE_THREAD_SUCCESS';
export const RENAME_PRODUCT_THREAD_FILE_SUCCESS =
  'RENAME_PRODUCT_THREAD_FILE_SUCCESS';
export const RENAME_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS =
  'RENAME_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS';
export const DELETE_PRODUCT_GENERAL_THREAD_SUCCESS =
  'DELETE_PRODUCT_GENERAL_THREAD_SUCCESS';
export const DELETE_PRODUCT_INSTANCE_THREAD_SUCCESS =
  'DELETE_PRODUCT_INSTANCE_THREAD_SUCCESS';
export const MARK_PRODUCT_GENERAL_THREAD_AS_DONE_SUCCESS =
  'MARK_PRODUCT_GENERAL_THREAD_AS_DONE_SUCCESS';
export const MARK_PRODUCT_INSTANCE_THREAD_AS_DONE_SUCCESS =
  'MARK_PRODUCT_INSTANCE_THREAD_AS_DONE_SUCCESS';
export const DELETE_PRODUCT_THREAD_FILE_SUCCESS =
  'DELETE_PRODUCT_THREAD_FILE_SUCCESS';
export const DELETE_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS =
  'DELETE_PRODUCT_INSTANCE_THREAD_FILE_SUCCESS';
export const GET_CRIBWISE_CREDENTIALS_SUCCESS =
  'GET_CRIBWISE_CREDENTIALS_SUCCESS';
export const EDIT_CRIBWISE_CREDENTIALS_SUCCESS =
  'EDIT_CRIBWISE_CREDENTIALS_SUCCESS';
export const UPDATE_PRODUCT_INSTANCE_RECONDITION_COUNT_SUCCESS =
  'UPDATE_PRODUCT_INSTANCE_RECONDITION_COUNT_SUCCESS';
export const CHECK_PRODUCT_INSTANCE_RECONDITION_DATA_SUCCESS =
  'CHECK_PRODUCT_INSTANCE_RECONDITION_DATA_SUCCESS';
export const SET_PRODUCT_INSTANCE_SUCCESS = 'SET_PRODUCT_INSTANCE_SUCCESS';

// Tag
export const GET_SEARCH_PRODUCTS_SUCCESS = 'GET_SEARCH_PRODUCTS_SUCCESS';
export const SET_EMPTY_TAG = 'SET_EMPTY_TAG';
export const SET_OCCUPIED_TAG = 'SET_OCCUPIED_TAG';
export const GET_SCANNED_TAGS_SUCCESS = 'GET_SCANNED_TAGS_SUCCESS';
export const SET_ERROR_SUCCESS = 'SET_ERROR_SUCCESS';

// Assembly
export const GET_ASSEMBLIES_SUCCESS = 'GET_ASSEMBLIES_SUCCESS';
export const SET_ASSEMBLIES_SUCCESS = 'SET_ASSEMBLIES_SUCCESS';
export const SET_ASSEMBLIES_TO_ADD_TO_START_SUCCESS =
  'SET_ASSEMBLIES_TO_ADD_TO_START_SUCCESS';
export const ADD_ASSEMBLIES_TO_START_SUCCESS =
  'ADD_ASSEMBLIES_TO_START_SUCCESS';
export const SET_ASSEMBLY_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS =
  'SET_ASSEMBLY_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS';
export const CREATE_ASSEMBLY_INSTANCE_SUCCESS =
  'CREATE_ASSEMBLY_INSTANCE_SUCCESS';
export const GET_ASSEMBLY_SUCCESS = 'GET_ASSEMBLY_SUCCESS';
export const GET_ASSEMBLY_INSTANCE_SUCCESS = 'GET_ASSEMBLY_INSTANCE_SUCCESS';
export const GET_ASSEMBLY_INSTANCE_LOCATIONS_SUCCESS =
  'GET_ASSEMBLY_INSTANCE_LOCATIONS_SUCCESS';
export const DELETE_ASSEMBLY_SUCCESS = 'DELETE_ASSEMBLY_SUCCESS';
export const DELETE_ASSEMBLY_OF_GROUP_SUCCESS =
  'DELETE_ASSEMBLY_OF_GROUP_SUCCESS';
export const CREATE_ASSEMBLY_SUCCESS = 'CREATE_ASSEMBLY_SUCCESS';
export const HIGHLIGHT_ASSEMBLY_DETAILS_SUCCESS =
  'HIGHLIGHT_ASSEMBLY_DETAILS_SUCCESS';
export const EDIT_ASSEMBLY_SUCCESS = 'EDIT_ASSEMBLY_SUCCESS';
export const CLEAR_ASSEMBLY_INSTANCE_SUCCESS =
  'CLEAR_ASSEMBLY_INSTANCE_SUCCESS';
export const EDIT_ASSEMBLY_INSTANCE_SUCCESS = 'EDIT_ASSEMBLY_INSTANCE_SUCCESS';
export const HIGHLIGHT_ASSEMBLY_INSTANCE_EMPTY_TAG_SUCCESS =
  'HIGHLIGHT_ASSEMBLY_INSTANCE_EMPTY_TAG_SUCCESS';
export const HIGHLIGHT_ASSEMBLY_INSTANCE_PRODUCTS_SUCCESS =
  'HIGHLIGHT_ASSEMBLY_INSTANCE_PRODUCTS_SUCCESS';
export const SET_ASSEMBLY_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS =
  'SET_ASSEMBLY_INSTANCE_TIME_TRACKER_PROPERTY_SUCCESS';
export const GET_ASSEMBLY_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS =
  'GET_ASSEMBLY_INSTANCE_TIME_TRACKER_HISTORY_SUCCESS';
export const SET_ASSEMBLY_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID =
  'SET_ASSEMBLY_INSTANCE_TIME_TRACKER_HIGHLIGHTED_ID';
export const GET_ASSEMBLY_INSTANCE_CONDITION_RATINGS_SUCCESS =
  'GET_ASSEMBLY_INSTANCE_CONDITION_RATINGS_SUCCESS';
export const SET_ASSEMBLY_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID =
  'SET_ASSEMBLY_INSTANCE_CONDITION_RATING_HIGHLIGHTED_ID';
export const ADD_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS =
  'ADD_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS';
export const DELETE_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS =
  'DELETE_ASSEMBLY_INSTANCE_CONDITION_RATING_SUCCESS';
export const GET_ASSEMBLY_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS =
  'GET_ASSEMBLY_INSTANCE_PROPERTIES_FROM_DETAILS_SUCCESS';
export const HIGHLIGHT_ASSEMBLY_THREAD_SUCCESS =
  'HIGHLIGHT_ASSEMBLY_THREAD_SUCCESS';
export const GET_ASSEMBLY_THREADS_SUCCESS = 'GET_ASSEMBLY_THREADS_SUCCESS';
export const GET_ASSEMBLY_INSTANCE_THREADS_SUCCESS =
  'GET_ASSEMBLY_INSTANCE_THREADS_SUCCESS';
export const ADD_ASSEMBLY_GENERAL_THREAD_SUCCESS =
  'ADD_ASSEMBLY_GENERAL_THREAD_SUCCESS';
export const ADD_ASSEMBLY_INSTANCE_THREAD_SUCCESS =
  'ADD_ASSEMBLY_INSTANCE_THREAD_SUCCESS';
export const EDIT_ASSEMBLY_GENERAL_THREAD_SUCCESS =
  'EDIT_ASSEMBLY_GENERAL_THREAD_SUCCESS';
export const EDIT_ASSEMBLY_INSTANCE_THREAD_SUCCESS =
  'EDIT_ASSEMBLY_INSTANCE_THREAD_SUCCESS';
export const RENAME_ASSEMBLY_THREAD_FILE_SUCCESS =
  'RENAME_ASSEMBLY_THREAD_FILE_SUCCESS';
export const RENAME_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS =
  'RENAME_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS';
export const DELETE_ASSEMBLY_THREAD_FILE_SUCCESS =
  'DELETE_ASSEMBLY_THREAD_FILE_SUCCESS';
export const DELETE_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS =
  'DELETE_ASSEMBLY_INSTANCE_THREAD_FILE_SUCCESS';
export const DELETE_ASSEMBLY_GENERAL_THREAD_SUCCESS =
  'DELETE_ASSEMBLY_GENERAL_THREAD_SUCCESS';
export const DELETE_ASSEMBLY_INSTANCE_THREAD_SUCCESS =
  'DELETE_ASSEMBLY_INSTANCE_THREAD_SUCCESS';
export const MARK_ASSEMBLY_GENERAL_THREAD_AS_DONE_SUCCESS =
  'MARK_ASSEMBLY_GENERAL_THREAD_AS_DONE_SUCCESS';
export const MARK_ASSEMBLY_INSTANCE_THREAD_AS_DONE_SUCCESS =
  'MARK_ASSEMBLY_INSTANCE_THREAD_AS_DONE_SUCCESS';

// Box
export const GET_BOX_DETAILS_SUCCESS = 'GET_BOX_DETAILS_SUCCESS';
export const GET_BOX_PRODUCT_INSTANCES_SUCCESS =
  'GET_BOX_PRODUCT_INSTANCES_SUCCESS';
export const SET_BOX_PRODUCT_INSTANCES_SUCCESS =
  'SET_BOX_PRODUCT_INSTANCES_SUCCESS';
export const ADD_SPECIAL_REQUEST_TO_PRODUCT_INSTANCE_IN_THE_BOX =
  'ADD_SPECIAL_REQUEST_TO_PRODUCT_INSTANCE_IN_THE_BOX';
export const ADD_PRODUCT_INSTANCE_TO_THE_BOX_SUCCESS =
  'ADD_PRODUCT_INSTANCE_TO_THE_BOX_SUCCESS';
export const GET_BOXES_SUCCESS = 'GET_BOXES_SUCCESS';
export const ACTIVATE_BOXES_SUCCESS = 'ACTIVATE_BOXES_SUCCESS';
export const RESET_BOXES_SUCCESS = 'RESET_BOXES_SUCCESS';
export const UPDATE_BOX_SUCCESS = 'UPDATE_BOX_SUCCESS';
export const SET_HIGHLIGHTED_BOXES_IDS_SUCCESS =
  'SET_HIGHLIGHTED_BOXES_IDS_SUCCESS';
export const SET_BOXES_SUCCESS = 'SET_BOXES_SUCCESS';
export const SET_DISPLAYED_BOXES_STATUSES_SUCCESS =
  'SET_DISPLAYED_BOXES_STATUSES_SUCCESS';
export const REMOVE_HIGHLIGHTED_IDS_SUCCESS = 'REMOVE_HIGHLIGHTED_IDS_SUCCESS';
export const FETCH_PRODUCT_INSTANCE_RECONDITIONING_DETAILS_SUCCESS =
  'FETCH_PRODUCT_INSTANCE_RECONDITIONING_DETAILS_SUCCESS';
export const ADD_PRODUCT_INSTANCE_RECONDITIONING_INFO_SUCCESS =
  'ADD_PRODUCT_INSTANCE_RECONDITIONING_INFO_SUCCESS';
export const SET_BOXES_TO_ADD_TO_START_SUCCESS =
  'SET_BOXES_TO_ADD_TO_START_SUCCESS';
export const ADD_BOXES_TO_START_SUCCESS = 'ADD_BOXES_TO_START_SUCCESS';
export const SET_BOXES_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS =
  'SET_BOXES_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS';
export const SYNC_TAG_TO_BOX_SUCCESS = 'SYNC_TAG_TO_BOX_SUCCESS';
export const DELETE_BOX_TAG_SUCCESS = 'DELETE_BOX_TAG_SUCCESS';
export const REMOVE_PRODUCT_INSTANCES_FROM_THE_BOX_SUCCESS =
  'REMOVE_PRODUCT_INSTANCES_FROM_THE_BOX_SUCCESS';

// Order
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS';
export const GET_BOXES_BY_ORDER_SUCCESS = 'GET_BOXES_BY_ORDER_SUCCESS';
export const SET_ORDER_BOXES_SUCCESS = 'SET_ORDER_BOXES_SUCCESS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const SET_ORDERS_SUCCESS = 'SET_ORDERS_SUCCESS';
export const GET_RECON_CENTER_ORDERS_SUCCESS =
  'GET_RECON_CENTER_ORDERS_SUCCESS';
export const ADD_ORDERS_TO_START_SUCCESS = 'ADD_ORDERS_TO_START_SUCCESS';
export const SET_DISPLAYED_ORDERS_STATUSES_SUCCESS =
  'SET_DISPLAYED_ORDERS_STATUSES_SUCCESS';
export const SET_ORDERS_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS =
  'SET_ORDERS_IDS_TO_FILTER_OUT_WHILE_FETCHING_SUCCESS';
export const SET_HIGHLIGHTED_ORDERS_IDS_SUCCESS =
  'SET_HIGHLIGHTED_ORDERS_IDS_SUCCESS';
export const SET_ORDERS_TO_ADD_TO_START_SUCCESS =
  'SET_ORDERS_TO_ADD_TO_START_SUCCESS';
export const GET_TOOLS_FROM_ORDERS = 'GET_TOOLS_FROM_ORDERS';
export const SET_TOOLS_SUCCESS = 'SET_TOOLS_SUCCESS';
export const ADD_ORDER_DETAILS_SUCCESS = 'ADD_ORDER_DETAILS_SUCCESS';
export const EDIT_ORDER_DETAILS_SUCCESS = 'EDIT_ORDER_DETAILS_SUCCESS';
export const UPDATE_RC_FEEDBACK_INSIDE_ORDER_DETAILS_SUCCESS =
  'UPDATE_RC_FEEDBACK_INSIDE_ORDER_DETAILS_SUCCESS';

// Inspection
export const GET_INSPECTION_BOX_PRODUCT_INSTANCES_SUCCESS =
  'GET_INSPECTION_BOX_PRODUCT_INSTANCES_SUCCESS';
export const GET_BOX_INSPECTION_DETAILS_SUCCESS =
  'GET_BOX_INSPECTION_DETAILS_SUCCESS';
export const SET_BOX_INSPECTION_PRODUCT_INSTANCES_SUCCESS =
  'SET_BOX_INSPECTION_PRODUCT_INSTANCES_SUCCESS';
export const UPDATE_IS_BOX_INSPECTED_SUCCESS =
  'UPDATE_IS_BOX_INSPECTED_SUCCESS';
export const UPDATE_PRODUCT_INSTANCE_INSPECTION_DETAILS_SUCCESS =
  'UPDATE_PRODUCT_INSTANCE_INSPECTION_DETAILS_SUCCESS';
export const ADD_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS =
  'ADD_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS';
export const DELETE_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS =
  'DELETE_PRODUCT_INSTANCE_RC_COMMENT_SUCCESS';
export const UPDATE_BOX_STATUS_SUCCESS = 'UPDATE_BOX_STATUS_SUCCESS';
export const UPDATE_IS_BOX_VERIFIED_SUCCESS = 'UPDATE_IS_BOX_VERIFIED_SUCCESS';
export const UPDATE_PRODUCT_INSTANCE_VERIFICATION_DETAILS_SUCCESS =
  'UPDATE_PRODUCT_INSTANCE_VERIFICATION_DETAILS_SUCCESS';
export const UPDATE_RC_FEEDBACK_INSIDE_INSPECTION_DETAILS_SUCCESS =
  'UPDATE_RC_FEEDBACK_INSIDE_INSPECTION_DETAILS_SUCCESS';

// Counterfeit risk
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';
export const SET_REQUESTS_SUCCESS = 'SET_REQUESTS_SUCCESS';
export const SET_COUNTERFEIT_RISK_HIGHLIGHTED_IDS_SUCCESS =
  'SET_COUNTERFEIT_RISK_HIGHLIGHTED_IDS_SUCCESS';
export const SEND_COUNTERFEIT_REQUEST_SUCCESS =
  'SEND_COUNTERFEIT_REQUEST_SUCCESS';
export const SET_COUNTERFEIT_REQUEST_DETAILS_SUCCESS =
  'SET_COUNTERFEIT_REQUEST_DETAILS_SUCCESS';

// User
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
export const UPDATE_USER_SETTINGS_PREFERENCES_SUCCESS =
  'UPDATE_USER_SETTINGS_PREFERENCES_SUCCESS';
export const SET_MY_DETAILS_SUCCESS = 'SET_MY_DETAILS_SUCCESS';

// Messages
export const GET_SUPPORT_MESSAGES_SUCCESS = 'GET_SUPPORT_MESSAGES_SUCCESS';
export const UPDATE_SUPPORT_MESSAGE_SUCCESS = 'UPDATE_SUPPORT_MESSAGE_SUCCESS';
export const ADD_SUPPORT_MESSAGE_SUCCESS = 'ADD_SUPPORT_MESSAGE_SUCCESS';

// Application
export const GET_JOB_TITLES_SUCCESS = 'GET_JOB_TITLES_SUCCESS';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_SERVER_METADATA_SUCCESS = 'GET_SERVER_METADATA_SUCCESS';
export const SET_VIEW_OPTION_SUCCESS = 'SET_VIEW_OPTION_SUCCESS';

// Notification
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS =
  'GET_UNREAD_NOTIFICATIONS_COUNT_SUCCESS';
export const CONNECTION_STATUS_CHANGE_SUCCESS =
  'CONNECTION_STATUS_CHANGE_SUCCESS';
export const UPDATE_NOTIFICATION_STATUS_SUCCESS =
  'UPDATE_NOTIFICATION_STATUS_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS =
  'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS';

// Pagination
export const GET_PAGINATION_DETAILS_SUCCESS = 'GET_PAGINATION_DETAILS_SUCCESS';
export const RESET_PAGINATION_DETAILS_SUCCESS =
  'RESET_PAGINATION_DETAILS_SUCCESS';
export const SET_CURRENT_PAGE_SUCCESS = 'SET_CURRENT_PAGE_SUCCESS';

// Breadcrumbs
export const SET_BREADCRUMBS_SUCCESS = 'SET_BREADCRUMBS_SUCCESS';

// Error
export const SET_ERROR = 'SET_ERROR';

// Util
export const SET_TRIGGER_REFETCHING_OF_DATA = 'SET_TRIGGER_REFETCHING_OF_DATA';

// Log
export const LOG_SUCCESS = 'LOG_SUCCESS';
