import React, { useState } from 'react';

import cs from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as InfoIcon } from '../../assets/icon/ic_btn_info.svg';
import { ReactComponent as CrossIcon } from '../../assets/icon/new-icons-ic-close-blue.svg';

const TwoSideCard = ({
  header,
  frontSide = <div />,
  backSide = <div />,
  frontSideFooter,
  backSideFooter,
  id,
  frontSideContainerClassName = '',
  backSideContainerClassName = '',
  onClick,
  isHighlighted,
  handleAnimationStart,
  handleAnimationEnd,
  containerClassName = '',
  children,
}) => {
  const [frontSideActive, setFrontSideActive] = useState(true);

  return (
    <div
      onClick={onClick}
      className={cs(
        `white-card rounded-edges two-side-card d-flex flex-column align-items-center font-size-md p-1 justify-content-center text-center ${
          frontSideActive
            ? frontSideContainerClassName
            : backSideContainerClassName
        } ${containerClassName}`,
        {
          'cursor-pointer': onClick,
          highlighted: isHighlighted,
        }
      )}
      onAnimationStart={handleAnimationStart}
      onAnimationEnd={handleAnimationEnd}
      key={id}
      id={id}
    >
      {header}
      {frontSideActive ? (
        <>
          <InfoIcon
            className='icon-txt-primary icon-hover-secondary top-right-icon double-click-disabled'
            width={24}
            height={24}
            id={`info-${id}`}
            onClick={(e) => {
              e.stopPropagation();
              setFrontSideActive(false);
            }}
          />
          {frontSide}
        </>
      ) : (
        <div className='d-flex flex-column align-items-center justify-content-between font-size-sm my-2'>
          <CrossIcon
            className='icon-txt-primary hoverable top-right-icon double-click-disabled'
            width={24}
            height={24}
            onClick={(e) => {
              e.stopPropagation();
              setFrontSideActive(true);
            }}
            id={`cross-${id}`}
          />
          {backSide}
        </div>
      )}
      {frontSideActive ? frontSideFooter : backSideFooter}
      {children}
    </div>
  );
};

TwoSideCard.propTypes = {
  header: PropTypes.any,
  frontSide: PropTypes.any,
  backSide: PropTypes.any,
  frontSideFooter: PropTypes.any,
  backSideFooter: PropTypes.any,
  id: PropTypes.string,
  frontSideContainerClassName: PropTypes.string,
  backSideContainerClassName: PropTypes.string,
  onClick: PropTypes.func,
  isHighlighted: PropTypes.bool,
  handleAnimationStart: PropTypes.func,
  handleAnimationEnd: PropTypes.func,
  containerClassName: PropTypes.string,
  children: PropTypes.any,
};

export default TwoSideCard;
