import {
  APP_NAME_STORAGE_KEY,
  DEFAULT_APP_TITLE,
  TITLE_STORAGE_KEY,
} from '../constants/theme';

export const initializeTheme = (theme) => {
  const { colors, faviconUrl, fonts, appName, title } = theme;

  // inject theme colors
  const r = document.querySelector(':root');

  Object.entries(colors).forEach(([name, color]) => {
    r.style.setProperty(`--${name}`, color);
  });

  // favicon
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }
  link.href = faviconUrl;

  // font
  r.style.setProperty('--font-family', fonts.primaryWeb);

  // app name for footer
  localStorage.setItem(APP_NAME_STORAGE_KEY, appName || DEFAULT_APP_TITLE);
  localStorage.setItem(TITLE_STORAGE_KEY, title);
};

export const getThemeHexColor = (color) => {
  const root = document.querySelector(':root');
  const rootProperties = window.getComputedStyle(root);
  return rootProperties.getPropertyValue(color.key) || color.fallbackValue;
};
