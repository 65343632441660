import React from 'react';

import { ENCODING_TYPES, IMAGE_FILES_EXTENSIONS } from '../constants';
import { extractExtension } from './file';
import { ReactComponent as DefaultFileTypeIcon } from '../assets/icon/ic_default_file_type.svg';
import { ReactComponent as ImageFileTypeIcon } from '../assets/icon/ic_image_file_type.svg';

export const getCoverImage = (images) => {
  if (!images || !images.length) {
    return null;
  }

  const coverImage = images.find((image) => image?.name === 'Cover');
  const { url } = coverImage || images[0];

  return url;
};

export const encodeToBase64 = (data) => {
  return Buffer.from(data, ENCODING_TYPES.BINARY).toString(
    ENCODING_TYPES.BASE64
  );
};

export const getFileIcon = (file, size = 'sm', className = 'mr-2') => {
  const SIZE = {
    sm: 28,
    md: 33,
  };

  if (IMAGE_FILES_EXTENSIONS.includes(extractExtension(file))) {
    return (
      <div>
        <ImageFileTypeIcon
          width={SIZE[size]}
          height={SIZE[size]}
          className={`icon-secondary ${className}`}
        />
      </div>
    );
  }

  return (
    <div>
      <DefaultFileTypeIcon
        width={SIZE[size]}
        height={SIZE[size]}
        className={`icon-secondary ${className}`}
      />
    </div>
  );
};

export const getProductImage = async (imageUrl) => {
  const response = await fetch(imageUrl);
  return response.text();
};

export const filterImagesFromFiles = (files = []) =>
  files.filter(({ name }) =>
    IMAGE_FILES_EXTENSIONS.includes(extractExtension(name))
  ) || [];
