// Handles the bug from Reactstraps Modal component https://github.com/reactstrap/reactstrap/issues/1323
export const removeModalOpenClass = () =>
  document.body.classList.remove('modal-open');

export const blurFocusedElementBeforeScanning = () => {
  const focusedElement = document.activeElement;

  try {
    if (
      focusedElement &&
      focusedElement !== document.body &&
      focusedElement.type !== 'text' // We don't want to ignore text inputs
    ) {
      // example: in inspection if "View" button was clicked
      // once something is scanned it will trigger that button onClick method again
      // usually happens in tables
      focusedElement.blur();
    }
  } catch {
    // Swallow error
  }
};
